@import '../../../shared/style/index.scss';

.container {
    width: 100%;
    min-height: 200px;
    @include centerFlex;
    flex-direction: column;
    padding: 40px 0;
    flex-wrap: wrap;
    margin: 25px 0;

    .content {
        height: auto;
        width: calc(70% - 50px);
        border-radius: 40px 0 40px 0;
        overflow: hidden;
        background: $gradient;

        header {
            background: $color-secondary;
            color: $color-primary;

            h2 {
                text-align: center;
                padding: 15px 30px;
                font-size: 30px;
                font-weight: bold;
            }
        }

        .cardContainer {
            display: flex;
            flex-wrap: wrap;
        }

        @media (max-width: 1000px) {
            width: 90%;
        }

        @media (max-width: 620px) {
            h2 {
                font-size: 25px !important;
            }

            .cardContainer {
                @include columnCenterFlex;
            }
        }
    }
}
