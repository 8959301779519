.choice {
    .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        padding-top: 20px;
        .card {
            width: calc(90% - 20px);
            max-width: 650px;
            margin: 10px;
            border: solid 1px black;
            padding: 30px;
            border-radius: 20px;
            position: relative;
            margin-bottom: 30px;
            overflow: hidden;
            height: 280px;
            cursor: pointer;
            transition: all 0.5s;
            img {
                width: auto;
                position: relative;
                left: 50%;
                transform: translate(-50%, 0);
                height: 100%;
            }
            p {
                width: 100%;
                text-align: center;
                background: var(--v-dark-blue);
                position: absolute;
                font-size: 18px;
                color: white;
                padding: 30px;
                left: 50%;
                bottom: 0%;
                transform: translate(-50%, 0);
                transition: all 0.5s;
            }
            &:hover {
                transition: all 0.5s;
                border: var(--bg-vt);
                p {
                    background: var(--bg-vt);
                }
            }
        }
    }
}
