@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.icon {
    @include centerFlex();

    i {
        color: $white;
        font-size: $small-font;
    }
}

.green {
    i {
        color: $color-quaternary;
    }
}

.blue {
    i {
        color: $color-secondary;
    }
}
