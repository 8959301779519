@import '../../shared/style/index.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0 10px;
    transform: translateY(-25px);

    .content {
        @include columnCenterTopFlex;
        .logo {
            width: 120px;
            height: 120px;
            border: 3px solid transparent;
            background: linear-gradient(to right, white, white) padding-box, $gradient border-box;
            border-radius: 20px;
            padding: 10px;

            img {
                filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.4));
            }

            .resize {
                padding: 10px;
            }
        }

        .title {
            color: $color-primary;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 20px;
            margin: 15px 0;
        }

        .text {
            font-weight: 600;
            text-align: center;
            margin-bottom: 10px;
            color: $color-secondary;
        }

        .jobsInTension {
            background: $gradient-opacity;
            display: flex;
            align-items: stretch;
            margin: 10px auto;
            text-align: center;
            border-radius: 10px;
            width: 95%;
            max-width: 480px;
            overflow: hidden;

            .icon {
                @include centerFlex;
                background: rgba(0, 0, 0, 0.233);
                padding: 10px;
                color: $color-secondary;
                i {
                    @include centerFlex;
                    align-items: baseline;
                    background: white;
                    height: 40px;
                    width: 40px;
                    padding: 10px;
                    border-radius: 50%;
                    border: 2px solid $color-secondary;
                    font-size: 19px;
                }
            }

            p {
                font-weight: bold;
                padding: 10px 5px;
            }
        }
    }

    .btn {
        margin-top: 20px;
    }
}
