@import '../../../shared/style/index.scss';

.card {
    display: flex;
    flex-direction: column;
    width: 90%;
    min-width: 90%;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: relative;
    margin: 15px 0;
    background: linear-gradient(white, white) padding-box,
        linear-gradient($color-secondary, $color-secondary) border-box;
    border: 2px solid transparent;

    .header {
        display: flex;
        align-items: center;
        background: $blue-gradient;
        border-radius: 17px 17px 0 0;
        width: 100%;
        padding: 5px 10px;
        height: max-content;
        max-height: 80px;

        .logo {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 45px;
            height: 45px;
            padding: 5px;
            background: $gradient;
            border-radius: 10px;
            margin-left: 15px;
            position: relative;

            i {
                color: $color-secondary;
                font-size: 25px;
            }
        }

        .profilImg {
            @include minAndMaxSize(120px, 120px);
            transform: translateY(30px);
            border-radius: 50%;
            overflow: hidden;
            margin-right: 20px;
            position: relative;

            img {
                transform: translateY(50px);
                transform-origin: top;
            }
            @media (max-width: 700px) {
                @include minAndMaxSize(100px, 100px);
                transform: translateY(10px);
                margin-right: 0;
                margin-bottom: 10px;
            }
            @media (max-width: 330px) {
                @include minAndMaxSize(80px, 80px);
                transform: translateY(10px);

                img {
                    transform: translateY(40px);
                }
            }
        }

        .title {
            @include title($color: $color-primary, $size-mobile: 17px);
            padding: 10px;
        }
    }

    .content {
        display: flex;
        padding: 15px;
        min-height: 30px;
        border-radius: 0px 0px 20px 20px;

        @media (max-width: 800px) {
            width: 100%;
            flex-direction: column;
            align-items: center;
        }
    }
}

// COULMN CONTENT
.column {
    flex-direction: column;
    align-items: center;

    & > div {
        width: 100% !important;
    }
}

// GRADIENT
.gradient {
    background: linear-gradient(to right, white, white) padding-box, $gradient border-box;
    margin-bottom: 15px;

    @media (max-width: 1300px) {
        width: 45%;
    }
    @media (max-width: 800px) {
        width: 90%;
    }

    .header {
        background: $gradient;

        .title {
            color: $color-secondary;
        }

        .logo {
            background: $color-secondary;

            i {
                color: transparent;
                background-image: linear-gradient(90deg, #71f2b8, #21bbee);
                -webkit-background-clip: text;
                background-clip: text;
            }
        }
    }
}

.noBorder {
    border: none;
    background: white;
}

// TOGGLE
.toggle {
    display: block;
}

.none {
    display: none;
}

.arrowToggle {
    background: $color-secondary;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    min-height: 40px;
    min-width: 40px;
    border: none;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: -22px;
    font-size: 1.2rem;

    i {
        transform: rotate(-90deg);
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        background-image: $gradient;
    }
}

.arrowToggleClose {
    background: $gradient;

    i {
        transform: rotate(90deg);
        color: $color-secondary;
    }
}

.H100 {
    max-height: 100%;
}

.cardHeightSmall {
    max-height: 30px;
    overflow: hidden;
    @media (max-width: 500px) {
        max-height: 90px;
    }
    @media (max-width: 400px) {
        max-height: 110px;
    }
}

.contentModal {
    @include columnCenterTopFlex();
}
