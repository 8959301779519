@import '../../../shared/style/index.scss';

.main {
    @include columnCenterFlex;
    text-align: center;
    width: 100%;

    .header {
        @include columnCenterFlex;
        width: 100%;
        // flex-wrap: wrap;

        h2 {
            font-size: 25px;
            margin: 10px;
            color: $color-secondary;
            min-width: 100px;
            width: max-content;
        }

        img {
            margin: 30px 10px;
            max-height: 150px;
        }
    }

    @media (max-width: 600px) {
        justify-content: flex-start;
    }
}
