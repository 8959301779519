@import '../../../shared/style/index.scss';

.container {
    background: $gradient-opacity;
    width: 90%;
    height: -webkit-fill-available;
    height: -moz-available;
    display: flex;
    flex-direction: column;
    margin: 5px 0px;
    border-radius: 10px;
    padding: 10px;

    .header {
        display: flex;
        align-items: center;
        background: $bg-opacity;
        border-radius: 10px;
        width: 90%;
        height: 58px;
        position: relative;
        align-self: center;
        margin-bottom: 10px;

        .logo {
            position: relative;
            width: 50px;
            min-width: 50px;
            height: 50px;
            min-height: 50px;
            background: white !important;
            border-radius: 50%;
            border: 3px solid $color-secondary;
            margin: 0px 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: 350px) {
                margin: 0px 5px;
            }

            img {
                border-radius: 50%;
            }
        }

        p {
            text-transform: uppercase;
            font-weight: bold;
            color: $color-secondary;
        }
    }

    .list {
        display: flex;
        align-items: center;
        margin-left: 45px;

        @media (min-width: 661px) and (max-width: 900px) {
            margin-left: 35px;
        }

        @media (min-width: 401px) and (max-width: 660px) {
            margin-left: 45px;
        }

        @media (max-width: 400px) {
            margin-left: 25px;
        }
        .circle {
            width: 15px;
            height: 15px;
            border: 2px solid var(--v-dark-blue);
            border-radius: 50%;
        }

        .on {
            background: var(--bg-vt);
        }

        p {
            margin: 5px;
            font-weight: 600;
            color: $color-secondary;
        }
    }
}
