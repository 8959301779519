@import '../../../shared/style/index.scss';

.basic {
    padding: 5px 15px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 15px;
    @include centerFlex();
    border-radius: 70px;
    border: 1px solid transparent;
    box-shadow: $soft-shadow;
    cursor: pointer;
    transition: transform 0.2s;
    background: $color-secondary;
    color: $white;
    &:hover {
        background: $border-basic;
        color: $color-secondary;
        transform: scale(1.03);
        .circle {
            background: $color-secondary;
        }
    }
}

.gradient {
    padding: 5px 15px;
    @include centerFlex;
    cursor: pointer;
    background: $gradient;
    border: 1px solid transparent;
    box-shadow: $soft-shadow;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    transition: transform 0.2s;
    border-radius: 20px;
    color: $color-secondary;
    .circle {
        background: $color-secondary;
    }
    &:hover {
        border-width: 1px;
        background: $border-gradient-bg-blue;
        color: $white;
        transform: scale(1.03);
        .circle {
            background: $gradient;
        }
    }
}

.btnGradient {
    margin-top: 20px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    background: linear-gradient(to right, #71f2b8, #21bbee) border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    border: 3px solid var(--v-dark-blue);
    border-radius: 50em;
    cursor: pointer;
    transition: all 0.2s;

    .circle {
        background: $color-secondary;
    }

    &:hover {
        background: linear-gradient(var(--v-dark-blue), var(--v-dark-blue)) padding-box,
            linear-gradient(to right, #71f2b8, #21bbee) border-box;
        border: 3px solid transparent;
        color: white;
        .circle {
            background: var(--bg-vt);
            transition: all 0.5s;
        }
    }
}

.btnSmallGradient {
    color: var(--v-dark-blue);
    width: 100%;
    font-size: 12px;
    margin: 0px;
    padding: 7px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: linear-gradient(to right, #71f2b8, #21bbee) padding-box,
        linear-gradient(to right, #71f2b8, #21bbee) border-box;
    border-radius: 50em;
    border: 1px solid transparent;
    transition: all 0.5s;
    z-index: 20 !important;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    position: relative;
    transition: all 0.2s;

    p {
        width: auto;
    }

    .circle {
        width: 15px;
        height: 15px;
    }
    &:hover {
        background: linear-gradient(var(--v-dark-blue), var(--v-dark-blue)) padding-box,
            linear-gradient(to right, #71f2b8, #21bbee) border-box;

        .circle {
            background: var(--v-blue);
            transition: all 0.5s;

            i {
                color: var(--v-dark-blue);
            }
        }
        p {
            color: var(--v-blue);
        }
    }
}

.btnWhite {
    color: var(--v-dark-blue);
    padding: 9px 5px;
    text-align: center;
    font-size: 18px;
    margin: 10px;
    width: 60%;
    position: relative;
    display: flex;
    z-index: 20 !important;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: linear-gradient(white, white) padding-box,
        linear-gradient(var(--v-dark-blue), var(--v-dark-blue)) border-box;
    border-radius: 50em;
    border: 3px solid transparent;
    transition: all 0.5s;
    cursor: pointer;

    p {
        text-transform: uppercase;
        font-weight: bold;
    }
    &:hover {
        background: linear-gradient(var(--v-dark-blue), var(--v-dark-blue)) padding-box,
            linear-gradient(to right, #71f2b8, #21bbee) border-box;
        border: 3px solid transparent;
        color: white;
        .circle {
            background: var(--bg-vt);
            transition: all 0.5s;
        }
    }
}

.btnSmallBasic {
    color: var(--v-blue);
    width: 100%;
    font-size: 12px;
    margin: 0px;
    padding: 7px;
    display: flex;
    align-items: center;
    background: linear-gradient(var(--v-dark-blue), var(--v-dark-blue)) padding-box,
        linear-gradient(to right, #71f2b8, #21bbee) border-box;
    border-radius: 50em;
    border: 1px solid transparent;
    transition: all 0.5s;
    z-index: 20 !important;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    position: relative;
    cursor: pointer;

    p {
        width: auto;
    }

    .circle {
        width: 15px;
        height: 15px;
    }
    &:hover {
        background: linear-gradient(to right, #71f2b8, #21bbee) padding-box,
            linear-gradient(to right, white, white) border-box;
        transition: all 0.5s;

        .circle {
            background: var(--v-dark-blue);
            transition: all 0.5s;

            i {
                color: white;
            }
        }
        p {
            color: var(--v-dark-blue);
        }
    }
}

.circle {
    @include centerFlex();
    @include minAndMaxSize(25px, 25px);
    background: $gradient;
    border-radius: 50%;
    margin-left: 10px;
    color: $white;
    i {
        font-size: 12px;
    }
}

.borderLarge {
    border: 3px solid transparent;
}

.reverse {
    flex-direction: row-reverse;

    .circle {
        margin-right: 10px;
        margin-left: 0;
    }
}

.disable {
    opacity: 0.4;
    pointer-events: none;
}

.capitalize {
    text-transform: capitalize;
}

.pulse {
    animation-name: pulseBtn;
    animation-duration: 1.5s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-iteration-count: infinite;
}

@keyframes pulseBtn {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.03);
        background: $gradient;
        color: $color-secondary;
        border: 1px solid $color-secondary;
    }
    100% {
        transform: scale(1);
    }
}

.minimalist {
    @include flex();
    border: none;
    color: $color-quaternary;
    font-weight: bold;
    font-size: 16px;
    background: none;
    cursor: pointer;

    .circle {
        background: none;
        margin-left: 5px;

        i {
            color: $color-quaternary;
            font-size: 18px;
        }
    }

    &:hover {
        transition: transform 0.2s;
        transform: scale(1.025);
        color: $color-secondary;

        i {
            color: $color-secondary;
        }
    }
}

.textContent {
    @include resolution(mobile) {
        font-size: $small-font;
    }
}