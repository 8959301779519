@import '../../../shared/style/index.scss';

.page {
    background: $bg-page;
}

.main {
    @include gridMainPage;

    .content {
        @include columnCenterTopFlex;
        width: 100%;
        margin-top: 15px;

        section {
            padding: 10px 10px 60px 10px;
            overflow-x: hidden;
        }

        .content {
            display: flex;
            transition: all 0.3s;
            margin-left: 5px;
        }

        .cards {
            display: flex;
            flex-direction: row;
            padding-top: 20px;
            transition: all 0.3s;

            @media (max-width: 1023px) {
                overflow-x: scroll;
                scroll-snap-type: x mandatory;
                scroll-padding: 10px;
                scrollbar-width: none !important;
                -ms-overflow-style: none !important;
                &::-webkit-scrollbar {
                    display: none !important;
                }

                .slider {
                    display: none !important;
                }
            }
            @media (max-width: 400px) {
                transform: translateX(15px);
            }
        }
        .slider {
            @include sliderArrowBilan;
            z-index: 100;
            @media (max-width: 1023px) {
                display: none !important;
            }
        }
        .sliderRight {
            right: 0;
            border-radius: 20px 0 0 20px;
        }
        .sliderLeft {
            left: 0;
            border-radius: 0 20px 20px 0;
        }

        .isMobile {
            display: none;
            margin-top: 15px;
            section {
                padding: 0;
            }
            .blue {
                color: $color-primary;
                margin-top: 5px;
            }

            .iconReo {
                width: 80px;
                height: 80px;
                min-width: 80px;
                min-height: 80px;
                margin: 5px auto;
            }

            @media (max-width: 1020px) {
                @include columnCenterTopFlex;
            }
        }
    }

    .col {
        @include columnCenterTopFlex;
        width: 100%;
        .blue {
            color: $color-primary;
            margin-top: 5px;
            margin-bottom: 10px;
        }

        .iconReo {
            width: 50px;
            height: 50px;
            min-width: 50px;
            min-height: 50px;
            margin: 5px auto;
            filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.4));
        }

        .isMobile {
            @include columnCenterTopFlex;
            @media (max-width: 1020px) {
                display: none;
            }
        }
    }

    @media (max-width: 1020px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 80px;

        .content {
            width: 100%;
        }

        .col {
            width: 100%;
            @include centerFlex;

            section {
                width: 90%;
                margin-top: 10px;
            }
        }
    }

    @media (max-width: 470px) {
        .content {
            .isMobile {
                section {
                    width: 100%;
                }
            }
        }
        .col {
            section {
                width: 100%;
            }
        }
    }
}
