@import '../../../shared//style/variable';
@import '../../../shared//style/mixins';

.container {
    background: $gradient-opacity;
    width: 95%;
    height: -webkit-fill-available;
    height: -moz-available;
    display: flex;
    flex-direction: column;
    margin: 5px 0px;
    border-radius: 10px;
    padding: 10px;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 5px;
        background: $bg-opacity;
        border-radius: 10px;
        width: 100%;
        position: relative;
        align-self: center;
        margin-bottom: 10px;
        flex-wrap: wrap;

        .logo {
            position: relative;
            width: 50px;
            height: 50px;
            min-width: 50px;
            min-height: 50px;
            background: white !important;
            border-radius: 50%;
            border: 3px solid $color-secondary;
            overflow: hidden;
        }

        h3 {
            text-transform: uppercase;
            font-weight: bold;
            color: $color-secondary;
        }
    }

    .description {
        text-align: center;
        p {
            margin: 5px;
            font-weight: 500;
            color: $color-secondary;
        }
    }
}
