@import '../../shared/style/index.scss';

.row {
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 10px 0;
    position: relative;

    .rank {
        display: flex;
        align-items: center;
        width: max-content;
        margin-right: 5px;

        p {
            width: 20px;
            height: 20px;
            text-align: center;
            font-weight: bold;
        }
        img {
            width: 20px;
            height: 20px;
            // position: absolute;
            left: 3%;
            @media (max-width: 550px) {
                left: 4%;
            }
            @media (max-width: 320px) {
                left: 5%;
            }
        }
    }


    .totalNote {
        width: 100px;
        white-space: nowrap;
    }

    .gauge {
        background: $color-secondary;
        // width: 60%;
        max-width: 220px;
        height: 20px;
        border-radius: 20px;
        position: relative;
        margin: 0 10px;

        @media (min-width: 1600px) {
            max-width: 330px;
        }

        @media (max-width: 550px) {
            width: 50%;
        }
        @media (max-width: 320px) {
            width: 40%;
        }

        .meter {
            background: $gradient;
            width: 50%;
            height: 20px;
            border-radius: 9px;
            position: absolute;
            left: 0px;
        }
    }
}