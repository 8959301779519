@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.backdrop {
    @include backdrop($color: transparent);
}
.modal {
    @include outline();
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
    width: 100%;
    min-height: 30px;
    border-radius: 10px;
    z-index: $zindex-modal;
    background-color: $white;
    @include columnCenterTopFlex();
    
    p,
    span,
    div {
        padding: 7px 10px;
        cursor: pointer;
        width: 100%;

        &:hover {
            background: $bg-blue-hight-opacity;
        }
    }
}
