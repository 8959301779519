@import '../../../../shared/style/variable';
@import '../../../../shared/style/mixins';

.container {
    width: 95%;
    border-radius: $large;
    background: $gradient-low-opacity;
    @include columnCenterTopFlex();
    margin-top: $medium;
    padding: $small $x-small;

    .task {
        @include centerFlex();
        justify-content: space-between;
        width: 100%;
        padding: 15px 8px;
        position: relative;

        .information {
            display: flex;
            align-items: center;
            .logo {
                @include minAndMaxSize(65px, 65px);
                background: $white;
                border-radius: 50%;
                margin-right: 15px;
                z-index: 1;
                position: relative;

                .check {
                    @include minAndMaxSize(21px, 21px);
                    position: absolute;
                    bottom: -2px;
                    right: -5px;
                    background: $white;
                    border: 1px solid $color-secondary;
                    z-index: 10;
                    border-radius: 50%;
                    padding: 3px;
                }

                .checked {
                    background: $blue-gradient;
                }

                @media screen and (max-height: 500px) {
                    display: none;
                }
            }

            .description {
                display: flex;
                flex-direction: column;
                color: $color-secondary;

                .taskName {
                    @include title($color: $color-secondary);
                }

                .descriptionText {
                    font-size: 18px;
                    font-weight: 500;
                }

                .accomplishMobile,
                .referenceMobile {
                    display: none;
                }
            }

            .taskCompleted {
                opacity: 0.8;
                .taskName {
                    text-decoration: line-through;
                }
            }
        }

        .reference {
            display: flex;

            .accomplish {
                margin: 10px;

                .replay {
                    background: transparent;
                    color: $color-secondary;
                    border: 1px solid $color-secondary;
                }

                .errorMessage {
                    font-size: $small-font;
                    white-space: nowrap;
                    position: absolute;
                    bottom: -3px;
                    right: 5px;
                    animation: entrenceRight 0.25s cubic-bezier(0.39, 0.575, 0.565, 1) both;

                    @keyframes entrenceRight {
                        0% {
                            transform: scaleX(0.4);
                            transform-origin: 100% 100%;
                        }
                        100% {
                            transform: scaleX(1);
                            transform-origin: 100% 100%;
                        }
                    }
                }
            }
        }

        @include resolution(tablet) {
            @include columnCenterTopFlex();
            gap: $small;

            .information {
                @include columnCenterTopFlex();
                text-align: center;

                .logo {
                    margin: 0;
                    margin-bottom: $small;
                }
            }

            .reference {
                flex-wrap: wrap;
                justify-content: center;

                button {
                    margin-top: $x-small;
                }
            }
        }
    }

    .toggleContainer {
        @include columnCenterTopFlex();

        &Info {
            margin: $small $x-small;
            @include flex();

            @include resolution(tablet) {
                text-align: center;
                flex-direction: column;
            }
        }

        .servicesContainer {
            display: flex;
            align-items: stretch;
            justify-content: space-around;
            flex-wrap: wrap;

            section {
                width: 40%;
                min-width: 320px;
                max-width: 500px;
                height: auto;
                margin: $x-small;

                @include resolution(mobile) {
                    min-width: 100%;
                }
            }

            .contentCard {
                @include columnCenterTopFlex();
                justify-content: space-between;
                height: 100%;
                .edtech {
                    @include columnCenterTopFlex();
                    text-align: center;
                    color: $color-secondary;

                    .header {
                        @include columnCenterTopFlex();
                        margin-top: 10px;

                        h4 {
                            margin-top: 10px;
                            text-transform: uppercase;
                            font-weight: bold;
                            margin-bottom: 5px;
                        }
                    }

                    .content {
                        .tags {
                            @include centerFlex();
                            flex-wrap: wrap;
                            li {
                                margin: 15px;
                                font-weight: 600;
                                position: relative;
                                font-size: 15px;
                                cursor: initial;

                                &::before {
                                    content: '';
                                    position: absolute;
                                    left: -10px;
                                    top: 7px;
                                    @include minAndMaxSize(7px, 7px);
                                    background: $color-secondary;
                                    border-radius: 50%;
                                }
                            }
                        }

                        .textInfo {
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 3;
                            overflow: hidden;
                            margin: 10px;
                        }
                    }
                }
                .btn {
                    @include columnCenterTopFlex();
                    width: 100%;
                    margin-top: 10px;
                    margin-bottom: 20px;
                    padding: 0 5px;

                    button {
                        color: $color-quaternary;
                        text-transform: initial;

                        i {
                            color: $color-secondary;
                        }

                        &:hover {
                            color: $color-secondary;

                            i {
                                color: $color-quaternary;
                            }
                        }
                    }
                    .price {
                        @include priceStatus();
                    }
                }
            }
        }
    }

    @include resolution(mobile) {
        .task {
            padding: $x-small;
            .information {
                .logo {
                    @include minAndMaxSize(60px, 60px);
                }
            }
            .reference {
                display: none;
            }

            .description {
                @include columnCenterTopFlex();
                gap: $small;
                .accomplishMobile {
                    display: block !important;
                }

                .referenceMobile {
                    display: block !important;
                }
            }
        }

        .accomplish {
            display: none;
        }
    }
}

.contentModal {
    @include columnCenterTopFlex();
    justify-content: space-between;
    height: auto;
    min-height: 340px;
    > p {
        font-size: $large-font;

        @include resolution(mobile) {
            font-size: $medium-font;
        }
    }
}
