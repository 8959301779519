@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.page {
    @include pageInit();

    .container {
        @include columnCenterTopFlex();
        text-align: center;
        margin: 0 10px;

        img {
            max-width: 500px;
            margin: 20px auto;

            @include resolution(tablet) {
                max-width: 100%;
                max-height: 250px;
            }
            @include resolution(mobile) {
                max-width: 100%;
            }
        }

        h2 {
            @include title($transform: initial, $color: $color-secondary);
            margin: 10px 0;
        }

        .description {
            font-weight: 500;
        }

        .btn {
            @include centerFlex();
            margin: 40px 5px 20px 5px;

            span {
                font-weight: bold;
                margin: 0 30px;
            }

            @include resolution(mobile) {
                span {
                    margin: 0 10px;
                }
            }
            @include resolution(x-mobile) {
                flex-direction: column;

                span {
                    margin: 10px 0;
                }
            }
        }
    }
}
