@import '../../../shared/style/index.scss';

.card {
    background: $gradient;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    padding: 10px 0px;
    margin: 5px;

    .header {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;

        .bar {
            position: absolute;
            top: 50%;
            background: white;
            width: 100%;
            height: 10px;
            z-index: 1;
        }

        .logo {
            width: 80px;
            height: 80px;
            border: 2px solid $color-secondary;
            border-radius: 20px;
            padding: 15px;
            background: white;
            z-index: 10;

            img {
                filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.4));
            }
        }
    }

    .title {
        font-weight: bold;
        font-size: 20px;
        text-transform: uppercase;
        margin-top: 10px;
        text-align: center;
    }

    .description {
        margin: 15px 0px;
        width: 80%;
        text-align: center;
        font-weight: 600;
    }

    .btn {
        button {
            font-size: 13px;
            font-weight: bold;
            padding: 8px 10px;
        }
    }
}
