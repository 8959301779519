@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.header {
    .border {
        height: 10px;
        width: 100%;
        background: $blue-gradient;
    }
}

.roadmapContainer {
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;

    .roadmap {
        padding: 80px 0;
        position: relative;

        .rowDashed {
            width: 76%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 0);
            border-top: $color-secondary 5px dashed;
        }

        .stepRoadmap {
            @include centerFlex();
            justify-content: space-between;

            .step {
                background: transparent;
                @include centerFlex();
                flex-direction: column;
                flex-grow: 1;
                height: 190px;
                border-radius: 50px 0;
                margin: 0 40px;
                position: relative;

                img {
                    width: 60px;
                    z-index: 10;
                }

                p {
                    color: $color-secondary;
                    font-weight: bold;
                    font-size: 17px;
                    position: absolute;
                    bottom: 15px;
                }

                &::before {
                    content: '';
                    display: block;
                    background: white;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 3;
                    transform: translate(-50%, -50%);
                    width: 90px;
                    height: 90px;
                    border: dashed 4px $color-quaternary;
                    border-radius: 100%;
                    filter: grayscale(1);
                }

                &::after {
                    content: '';
                    display: block;
                    background: transparent;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 5;
                    transform: translate(-50%, -50%) rotate(-46deg);
                    width: 90px;
                    height: 90px;
                    border: solid 4px $color-primary;
                    border-color: transparent $color-primary $color-primary transparent;
                    border-radius: 100%;
                    filter: grayscale(1);
                }
            }

            .stepActive {
                background: $blue-gradient;
                min-width: 200px;
                max-width: 200px;
                min-height: 200px;
                max-height: 200px;

                &::after,
                &::before {
                    filter: grayscale(0) !important;
                    background: transparent;
                }

                p {
                    color: white;
                }
            }
        }
        @media (max-width: 700px) {
            .rowDashed {
                transform: translate(-50%, -50%);
                width: 0%;
                height: 72%;
                border-right: $color-secondary 5px dashed;
            }

            .stepRoadmap {
                flex-direction: column;

                p {
                    background-color: white;
                    padding: 10px;
                    bottom: 5px !important;
                }
            }

            .stepActive {
                p {
                    background: transparent;
                }
            }
        }
    }
}

.endContent {
    @include columnCenterTopFlex;
    color: $color-secondary;
    font-size: 18px;
    padding: 0 15px;

    img {
        width: 550px;
        height: 320px;
    }

    h3 {
        width: 90%;
        font-size: 25px;
        margin: 30px 0 20px 0;
        color: $color-tertiary;
        font-weight: bold;
    }

    p {
        width: 90%;
        margin-top: 10px;
    }

    .recapSharing {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 90%;
        margin-top: 10px;
        padding-bottom: 50px;
        .check {
            display: flex;
            margin: 10px 0;
            font-weight: 500;

            img {
                width: 40px;
                height: 40px;
                background: $color-tertiary;
                border-radius: 5px;
                padding: 10px;
                margin-right: 10px;
            }
        }
    }
}

.btn {
    @include centerFlex;
    padding-bottom: 80px;

    button {
        padding: 10px 15px;
    }
}
