.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0;

    .img {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;

        img {
            max-height: 250px;
        }
    }

    .text {
        font-weight: 600;
        font-size: 20px;
        margin: 30px 0;
        text-align: center;

        @media screen and (max-width: 370px) {
            margin: 10px 0;
        }
    }
}