@import '../../../../shared/style/index.scss';

.card {
    background: $gradient;
    width: 50%;
    height: auto;
    border-radius: 20px;
    margin: 15px 10px;
    @include columnCenterTopFlex;
    justify-content: space-between;
    text-align: center;
    padding: 15px;

    .img {
        width: 50px;
        height: 50px;
    }

    .textContent {
        h3 {
            color: $white;
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 700;
            margin: 5px 0;
        }

        p {
            color: $color-secondary;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 500;
        }
    }

    .score {
        margin-top: 15px;
        font-weight: 500;

        .round {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #a0ebf0;
            border-radius: 20px;
            margin: 10px 0;
            padding: 5px;

            span {
                margin-right: 5px;
                font-size: 20px;
                font-weight: bold;
            }

            img {
                width: 25px;
                height: 25px;
            }
        }
    }

    @media (max-width: 660px) {
        width: 95%;
    }
}

.blue {
    background: $color-secondary;

    .textContent {
        h3 {
            color: $color-primary;
        }

        p {
            color: $white;
        }
    }

    .btn {
        button {
            margin: 15px 0 10px 0;
            padding: 5px 15px;
            font-size: 14px;
        }
    }
}

.help {
    flex-direction: row;
    width: 90%;
    height: 220px;

    .content {
        @include columnCenterTopFlex;
        width: 50%;

        .textContent {
            p {
                font-size: 20px;

                span {
                    font-weight: bold;
                }
            }
        }

        .btn {
            text-decoration: none;
            button {
                padding: 8px 15px;
            }
        }
    }

    .img {
        width: 50%;
        height: inherit;
        img {
            width: 100%;
            max-height: 220px;
        }
    }

    @media (max-width: 470px) {
        .content {
            .textContent {
                p {
                    font-size: 15px;
                }
            }
        }
    }
}
