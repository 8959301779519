@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.spinner {
    width: 100%;
    height: 100%;
    @include centerFlex();
    gap: $x-small;

    span {
        background: $color-quaternary;
        width: $x-small;
        height: $medium;
        animation: grow 0.9s ease-in-out infinite;
        border-radius: $x-small;

        @for $i from 1 through 5 {
            &:nth-child(#{$i}) {
                animation-delay: 0.15s * $i;
            }
        }
    }
}

@keyframes grow {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scaleY(1.5);
    }
    100% {
        transform: scaleY(1);
    }
}
