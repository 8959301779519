@import '../../shared/style/index.scss';

.row {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    padding: 0px 15px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 17px;
            padding: 10px;
            color: $color-secondary;
        }

        .btn {
            margin-right: 20px;
            button {
                font-size: 14px;
            }
        }

        @media (max-width: 400px) {
            .btn {
                margin: 0;
                button {
                    div {
                        display: none;
                    }
                }
            }

            .title {
                padding: 10px 0;
            }
        }

        @media (max-width: 350px) {
            .title {
                font-size: 16px;
            }
        }
    }

    .content {
        display: flex;

        @media (max-width: 800px) {
            flex-direction: column;
            align-items: center;
            &> div {
                width: 100% !important;
            }
        }
    }
}

.bg {
    &:nth-child(2n) {
        background: #f6f6f8;
        // border-radius: 10px;
    }
}
