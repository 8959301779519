@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.pill {
    @include flex();
    justify-content: space-between;
    background: $white;
    border-radius: $small;
    padding: 6px 15px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: $normal-font;
    margin: 0 $small;
    box-shadow: $white-shadow;
    min-width: auto;
    position: relative;
    min-width: 100px;

    &:first-child {
        cursor: pointer;
    }
}

.border {
    @include borderGradient($border-size: 1px);
}
