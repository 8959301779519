@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.pill {
    @include centerFlex();
    background: $gradient;
    border-radius: $small;
    padding: 2px $small;

    img {
        @include minAndMaxSize(30px, 30px);
    }

    span {
        font-weight: bold;
        margin-left: 7px;
    }
}

.small {
    img {
        @include minAndMaxSize(25px, 25px);
    }
}

.large {
    img {
        @include minAndMaxSize(35px, 35px);
    }
}

.gradientOpacity {
    background: $gradient-low-opacity;
}

.blue {
    background: $blue-gradient;

    span {
        color: $color-quaternary;
    }
}