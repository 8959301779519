@import '../../../shared/style/index.scss';

.main {
    @include columnCenterFlex;
    height: calc(100vh - 90px);
    text-align: center;

    .header {
        h2 {
            font-size: 30px;
            margin: 10px;
            color: $color-secondary;
        }

        img {
            margin: 30px 10px;
            max-height: 350px;
        }
    }

    p {
        font-size: 20px;
        margin: 10px;
        color: $color-secondary;
    }

    @media (max-width: 600px) {
        justify-content: flex-start;
    }
}
