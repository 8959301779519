@import '../../../shared/style/index.scss';

.card {
    @include centerFlex();
    @include minAndMaxSize(140px, 140px);
    padding-bottom: 30px;
    background: $border-gradient;
    border: 2px solid transparent;
    border-radius: 20px;
    position: relative;
    margin: 10px;
    cursor: pointer;
    overflow: hidden;

    img {
        @include minAndMaxSize(100px, 100px);
        object-fit: contain;
    }

    .bg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        background: $gradient;
        border-radius: 0 0 17px 17px;

        .rewards {
            @include centerFlex();
            height: 100%;
            width: 100%;
            font-weight: 600;

            img {
                @include minAndMaxSize(20px, 20px);
                margin-right: 5px;
                object-fit: initial;
            }

            span {
                font-size: 14px;
            }
        }
    }

    @media screen and (max-width: 370px) {
        @include minAndMaxSize(120px, 120px);

        img {
            @include minAndMaxSize(80px, 80px);
            object-fit: contain;
        }
    }
}

.small {
    border: none;
    padding: 0px;
    @include minAndMaxSize(190px, 110px);
    box-shadow: $soft-shadow-content;
    cursor: initial;

    img {
        padding: 5px;
        object-fit: contain;
        max-height: 110px;
    }
}
