@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.switch {
    position: relative;
    @include centerFlex;
    margin: 0 15px;
    height: 30px;
    width: 60px;

    input {
        appearance: none;
    }
    .checked {
        background: $blue-gradient;
        border: 1px solid $color-secondary;

        &::before {
            transform: translateX(30px);
            background: $color-quaternary;
        }
    }
    .unChecked {
        background: $color-error;
        color: $white;
        border: 1px solid $color-error;
    }
    div {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: auto;
        margin-right: auto;
        font-weight: 600;
        width: 60px;
        height: 30px;
        @include centerFlex;
        span {
            border-radius: 5px;
            width: 100%;
            height: inherit;
            color: $color-secondary;
            transition: transform 0.2s;
            @include centerFlex;
            border-radius: 24px;

            &::before {
                position: absolute;
                content: '';
                height: 24px;
                width: 24px;
                left: 3.3px;
                bottom: 3px;
                background: white;
                transition: 0.3s;
                border-radius: 50%;
            }
        }
    }
}
