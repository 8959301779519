.container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 5px auto;
}

.start {
    justify-content: flex-start;
}

.end {
    justify-content: flex-end;
}
