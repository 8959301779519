@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.main {
    @include gridMainPage;
    background: $bg-page;
    margin-top: 10px;

    .container {
        @include columnCenterTopFlex;
        width: 100%;

        .cardInfo {
            width: 90%;
        }

        .summary {
            display: none;
        }

        .titleRewards {
            @include title($color: $color-secondary, $size: 19px !important);
            padding: 10px;
            margin-left: 20px;
        }

        .allRewards {
            @include centerFlex();
            align-items: stretch;
            flex-wrap: wrap;
            padding-bottom: 10px;

            @media (max-width: 800px) {
                & > div {
                    width: 100% !important;
                    margin: 10px !important;
                }
            }
        }
    }

    .col {
        display: flex;
        flex-direction: column;
        align-items: center;

        .summary {
            @include columnCenterTopFlex;
            width: 100%;
        }

        .cardInfo {
            display: none;
        }

        .topVisions {
            @include columnCenterTopFlex;
            width: 100%;
        }

        .expContainer {
            @include columnCenterTopFlex;
        }

        .linkCard {
            width: 95%;
        }
    }

    @media (max-width: 1300px) {
        .col {
            .expContainer {
                section {
                    width: 90%;
                }
            }
        }
    }

    @media (max-width: 1020px) {
        display: flex;
        flex-direction: column;
        // flex-direction: column-reverse;
        align-items: center;

        .container {
            .summary {
                @include centerFlex;
                width: 100%;
            }
        }

        .col {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: max-content;
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            justify-items: center;
            align-items: flex-start;
            width: 95%;

            .summary {
                display: none;
            }

            .topVisions {
                grid-area: 2 / 1 / 4 / 2;
                display: flex;
                justify-content: center;
                section {
                    width: 90%;
                }
            }
            .linkCard {
                width: 95%;
                grid-area: 2 / 2 / 3 / 3;
            }

            .expContainer {
                order: 5;
                display: flex;
                justify-content: center;
                grid-area: 3 / 2 / 4 / 3;
                section {
                    width: 90%;
                }
            }
        }
    }

    @media (max-width: 900px) {
        .col {
            @include columnCenterTopFlex;
            width: 100%;
        }
    }
}
