@import '../../../shared/style/index.scss';

nav {
    @include centerFlex;
    justify-content: space-between;
    background: white;
    max-height: 80px;
    min-height: 80px;

    .logo {
        @include centerFlex;
        width: max-content;
        margin-left: 50px;

        img {
            height: 60px;
            cursor: pointer;
        }

        .large {
            height: 100px;
        }

        @media (max-width: 500px) {
            margin-left: 10px;
        }
    }

    .navbarMenu {
        @include centerFlex;
        margin-right: 50px;

        a {
            margin: 10px;
            color: $color-secondary;
            font-weight: 500;
            text-decoration: none;
        }

        span {
            cursor: pointer;
        }
    }

    .menuToggler {
        background: none;
        width: 35px;
        border: none;
        cursor: pointer;
        position: relative;
        outline: none;
        z-index: 999;
        display: none;

        span {
            display: block;
            height: 3px;
            background-color: $color-primary;
            margin: 5px 0;
            position: relative;
            transition: 0.3s linear;
            border-radius: 5px;
        }
    }
    // @media (max-width: 1000px) {
    //     .navbarMenu {
    //         right: 0 !important;
    //     }
    // }

    @media (max-width: 1020px) {
        .menuToggler {
            display: block;
            margin-right: 20px;
        }

        .navbarMenu {
            position: fixed;
            right: -100%;
            height: 100vh;
            width: 100%;
            background: $blue-gradien-top;
            top: 0;
            max-width: 400px;
            padding: 80px 50px;
            transition: 0.3s ease-in-out;
            z-index: 100 !important;
            flex-direction: column;
            justify-content: flex-start !important;
            align-items: flex-start !important;
            margin-right: 0 !important;

            a,
            span {
                color: white !important;
                font-size: 20px;
            }
        }
    }

    @media (max-width: 500px) {
        .menuToggler {
            margin-right: 10px;
        }
    }
}

.active {
    position: fixed !important;
    top: 27px;
    right: 0;
    span {
        &:first-child {
            transform: rotate(45deg);
            top: 4px;
        }
        &:nth-child(2) {
            transform: rotate(-45deg);
            top: -4px;
        }
        &:last-child {
            display: none;
        }
        background-color: $gradient !important;
    }
}

.navActive {
    right: 0 !important;
}
