@import '../../../shared/style/index.scss';

.bar {
    height: 8px;
    width: 100%;
    background: $gradient;
    margin: 15px 0 10px 0;

    @media (max-width: 500px) {
        width: 100% !important;
        margin: 10px 0;
    }
}