@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.page {
    @include pageInit();

    .container {
        @include columnCenterTopFlex();
        text-align: center;
        margin: 0 10px;

        img {
            max-width: 500px;
            margin: 20px auto;

            @include resolution(tablet) {
                max-width: 100%;
                max-height: 250px;
            }
            @include resolution(mobile) {
                max-width: 100%;
            }
        }

        h2 {
            @include title(
                $transform: initial,
                $color: $color-secondary,
                $size: $x-large-font,
                $size-mobile: $large-font
            );
        }

        h3 {
            @include title($transform: initial, $color: $color-secondary);
            margin: 10px 0;
        }

        .objectives {
            @include centerFlex();
            flex-wrap: wrap;
            margin: $large 0;
            position: relative;

            > div {
                margin: $small;
            }

            button {
                text-transform: initial;
                color: $color-quaternary;
                font-weight: 500;
                margin: $small;
                padding: 7px $large;

                &:hover {
                    color: $color-secondary;
                }
            }

            .errorMessage {
                font-size: $small-font;
                white-space: nowrap;
                position: absolute;
                bottom: -15px;
                right: 0px;
                animation: entrenceRight 0.25s cubic-bezier(0.39, 0.575, 0.565, 1) both;

                @keyframes entrenceRight {
                    0% {
                        transform: scaleX(0.4);
                        transform-origin: 100% 100%;
                    }
                    100% {
                        transform: scaleX(1);
                        transform-origin: 100% 100%;
                    }
                }
            }
        }

        .description {
            font-weight: 500;
            margin-top: $medium;
        }
    }
}
