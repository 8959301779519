@import '../../../shared/style/index.scss';

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    min-width: 160px;
    height: 120px;
    background: $gradient;
    margin: 10px;

    .logo {
        img {
            width: 70px;
            height: 50px;
        }
        .filter {
            filter: brightness(0.2);
        }
    }

    .title {
        color: $color-secondary;
        text-transform: uppercase;
        margin-top: 15px;
        h3 {
            font-weight: 600;
        }
    }

    @media (min-width: 1260px) and (max-width: 1460px) {
        min-width: 145px;
    }

    @media screen and (max-width: 450px) {
        min-width: 130px;

        .logo {
            img {
                @include minAndMaxSize(60px, 40px)
            }
        }


        .title {
            font-size: 14px;
        }
    }
}

.blue {
    background: $color-secondary;

    .title {
        color: white;
    }
}
