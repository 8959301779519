@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.page {
    @include pageInit();

    .container {
        @include gridMainPage();

        .content {
            @include columnCenterTopFlex();
            width: 100%;

            .banner {
                background: $gradient;
                width: 90%;
                border-radius: 20px;
                @include flex();
                justify-content: space-between;
                padding: 0 15px;
                margin: 15px 0;

                &Info {
                    h2 {
                        font-weight: bold;
                        font-size: 19px;
                    }

                    p {
                        font-weight: 500;
                    }
                }

                .logo {
                    @include minAndMaxSize(100px, 100px);
                    margin-top: 10px;

                    img {
                        filter: drop-shadow(0px 0px 1px black);
                    }
                }
            }

            .meetContainer {
                @include columnCenterTopFlex();

                .meet {
                    @include centerFlex();
                    @media (max-width: 700px) {
                        @include columnCenterTopFlex();
                        width: 100%;
                    }
                }

                button {
                    text-transform: initial;
                    color: $color-quaternary;
                    margin: 10px 0;
                    &:hover {
                        color: $color-secondary;
                    }
                }
            }
        }

        .col {
            @include columnCenterTopFlex();
            width: 100%;

            .meetRequest {
                @include columnCenterTopFlex();
                width: 100%;

                @media (min-width: 701px) and (max-width: 1020px) {
                    flex-direction: row;
                    justify-content: center;
                }
            }
        }

        @media (max-width: 1020px) {
            @include columnCenterTopFlex();
        }
    }
}
