@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.card {
    @include columnCenterTopFlex;
    justify-content: space-between;
    position: relative;
    width: 32%;
    min-width: 32%;
    background: $gradient-low-opacity;
    border-radius: $radius-mid;
    padding: 10px;
    padding-top: 30px;
    margin: 0px 5px;
    z-index: 10;
    scroll-snap-align: start;

    @media (max-width: 770px) {
        min-width: 47%;
        width: 47%;
    }

    @media (max-width: 500px) {
        min-width: 95%;
        width: 95%;
    }

    @media (max-width: 350px) {
        min-width: 100%;
        width: 100%;
    }

    .status {
        position: absolute;
        top: -15px;
        right: 15px;
        background: $color-secondary;
        padding: 10px 15px;
        border-radius: 10px;
        color: $white;
        text-transform: uppercase;
    }

    .container {
        margin: 10px 0;
        .header {
            display: flex;
            width: 100%;
            margin-bottom: 10px;
            .logo {
                overflow: hidden;
                width: auto;
                @include borderGradient;
                @include centerFlex;
                border-radius: $radius-mid;
                height: fit-content;
                margin-right: 10px;
                width: 85px;
                min-width: 85px;
                height: 85px;
                min-height: 85px;
            }

            .information {
                @include columnCenterTopFlex;
                align-items: flex-start;
                margin-top: 5px;
                font-weight: bold;
                width: auto;
                h3 {
                    font-size: 19px;
                    font-weight: bold;
                    text-transform: uppercase;
                }
                .time {
                    @include centerFlex;
                    justify-content: flex-start;
                    margin-top: 5px;
                    transform: translateX(-7px);
                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                    }
                }
            }

            @media (min-width: 1023px) and (max-width: 1350px) {
                flex-direction: column;
                align-items: center;
                margin-bottom: 20px;

                .information {
                    align-items: center;
                }
            }

            @media (min-width: 771px) and (max-width: 890px) {
                flex-direction: column;
                align-items: center;
                margin-bottom: 20px;

                .information {
                    align-items: center;
                }
            }

            @media (min-width: 501px) and (max-width: 620px) {
                flex-direction: column;
                align-items: center;
                margin-bottom: 20px;

                .information {
                    align-items: center;
                }
            }
        }

        .content {
            .description {
                font-weight: bold;
            }
            .stepGuide {
                .guideContainer {
                    display: flex;
                    margin: 15px 0;

                    .guideNum {
                        @include centerFlex;
                        font-weight: bold;
                        width: 30px;
                        min-width: 30px;
                        height: 30px;
                        min-height: 30px;
                        margin-right: 10px;
                        margin-top: 5px;
                        background: $gradient;
                        border: 2px solid $color-secondary;
                        border-radius: $radius-round;
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            top: -2.5px;
                            left: -4px;
                            background: $color-secondary;
                            border-radius: $radius-round;
                            width: 30px;
                            min-width: 30px;
                            height: 30px;
                            min-height: 30px;
                            z-index: -1;
                        }
                    }

                    .guideDesc {
                        font-weight: 500;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
    .btn {
        @include columnCenterTopFlex();
        margin: 10px 0;
        min-height: 32px;

        .price {
            @include priceStatus($color: $color-quaternary, $background: $blue-gradient-low-opacity);
            font-size: 14px;
            padding: 5px;
        }
    }

    @media (max-width: 1120px) {
        width: auto;
        margin: 20px 5px;
    }
}
