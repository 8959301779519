@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.page {
    @include pageInit();

    .container {
        @include columnCenterTopFlex();
        // @include gridMainPage();
        padding: 20px 0;

        .content {
            width: 95%;
            max-width: 1400px;
        }

        .col {
            @include columnCenterTopFlex();
            width: 95%;
            max-width: 1400px;

            .missionsFinished {
                width: 100%;
                border-radius: 20px;
                background: $white;
                padding: 10px;
                margin: 10px 0;

                > h3 {
                    font-weight: bold;
                    font-size: 19px;
                    text-transform: uppercase;
                    margin-bottom: 10px;
                }
            }
        }

        @media (max-width: 1020px) {
            @include columnCenterTopFlex();
        }
    }

    .modal {
        @include columnCenterTopFlex();
        width: 100%;
        text-align: center;
        h3 {
            margin: 10px 0;
            color: $white;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 19px;
        }

        .subTitle {
            font-weight: bold;
            font-size: 18px;
            margin: 10px 0;
        }

        .progress {
            width: 100%;
            margin-top: 10px;

            &Info {
                @include centerFlex();
                justify-content: space-between;

                p {
                    font-weight: 600;
                    &:first-child {
                        font-weight: 400;
                    }
                    font-size: 15px;
                    margin: -5px 0;
                }
            }
        }

        .startupTasks {
            .taskContainer {
                @include flex();
                margin: 20px 0;
                cursor: pointer;

                .iconTask {
                    @include minAndMaxSize(55px, 55px);
                    @include outline($color: $white, $border-width: 2px, $radius: 50%);
                    border-radius: 50%;
                    overflow: hidden;
                    margin-right: 10px;
                }

                .defaultImg {
                    background: $bg-blue-opacity;
                    position: relative;
                    &::after {
                        content: '?';
                        color: white;
                        font-size: 25px;
                        @include minAndMaxSize(55px, 55px);
                        position: absolute;
                        left: 0;
                    }
                }

                .contentTask {
                    @include columnCenterTopFlex();
                    align-items: flex-start;
                    text-align: start;
                    h4 {
                        font-size: 17px;
                        font-weight: bold;
                    }

                    p {
                        font-weight: 500;
                        font-size: 14px;
                        margin: 0;
                    }
                }
            }

            .completed {
                .contentTask {
                    opacity: 0.6;
                    h4 {
                        text-decoration: line-through;
                    }
                }
            }
        }
    }
}

.btn-blink {
    background-color: #1c87c9;
    -webkit-border-radius: 60px;
    border-radius: 60px;
    border: none;
    color: #eeeeee;
    cursor: pointer;
    display: inline-block;
    font-family: sans-serif;
    font-size: 20px;
    padding: 5px 15px;
    text-align: center;
    text-decoration: none;
}

@keyframes glowing {
    0% {
        background-color: $bg-blue-opacity;
        box-shadow: 0 0 5px $bg-blue-opacity;
    }
    50% {
        background-color: #49e819;
        box-shadow: 0 0 20px #49e819;
    }
    100% {
        background-color: $bg-blue-opacity;
        box-shadow: 0 0 5px $bg-blue-opacity;
    }
}

.btn-blink {
    animation: glowing 1300ms infinite;
}
