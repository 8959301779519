@import '../../../../shared/style/variable';
@import '../../../../shared/style/mixins';

.container {
    text-align: center;
    width: 100%;
    @include columnCenterFlex();
    @include animationSlideRightPage();

    .header {
        margin: 0 10px;
        width: 100%;
        h1 {
            color: $color-quaternary;
            font-size: 25px;
            margin-bottom: 10px;
            font-weight: 600;
            padding: 0 5px;
        }

        .VG {
            font-size: 40px;
            color: $white;
            margin-bottom: 30px;

            span {
                font-weight: bold;
            }
        }

        img {
            max-width: 260px;
            margin: 20px 0 10px 0;

            @media (max-width: 600px) {
                max-width: 230px !important;
            }
        }
    }

    .text {
        color: $white;
        font-size: 18px;
        width: 80%;
        margin: 20px 0;
    }
}

.slide {
    @include animationSlideLeftPage();
}
