@import '../../../shared/style/mixins';
@import '../../../shared/style/variable';

.card {
    width: 100%;
    background: $gradient;
    border-radius: 20px;
    padding: 10px 20px;
    @include centerFlex();
    justify-content: flex-start;
    margin: 10px 0;

    .icon {
        align-self: flex-start;
        margin-right: 20px;
        img {
            @include minAndMaxSize(50px, 50px);
        }
        @media (max-width: 1020px) {
            display: none;
        }
    }

    .content {
        @include columnCenterTopFlex();
        align-items: flex-start;
        width: 100%;

        .title {
            display: flex;
            align-items: center;

            h2 {
                color: $color-secondary;
                text-transform: uppercase;
                font-size: 20px;
                font-weight: bold;
                margin: 10px 0;
                margin-right: 15px;
            }

            .isMobile {
                display: none;
                @include minAndMaxSize(50px, 50px);
                margin-right: 5px;
            }

            .info {
                @include minAndMaxSize(20px, 20px);
                filter: brightness(0.1);
                cursor: pointer;
            }

            @media (max-width: 1020px) {
                .isMobile {
                    display: block;
                }
            }
        }

        .objectives {
            @include centerFlex();
            justify-content: flex-start;
            flex-wrap: wrap;

            p {
                font-weight: 500;
                font-size: 18px;
                margin: 10px 0;
                margin-right: 20px;
            }

            .pill {
                @include flex();
                justify-content: space-between;
                background: $white;
                border-radius: 10px;
                padding: 6px 15px;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 15px;
                box-shadow: $white-shadow;
                min-width: auto;
                position: relative;
                min-width: 100px;

                &:first-child {
                    cursor: pointer;
                }
            }
        }
    }
}
