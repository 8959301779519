@import '../../../shared/style/index.scss';

.page {
    @include pageInit();
    @include columnCenterTopFlex();

    .btnBack {
        align-self: flex-start;
    }

    .container {
        @include columnCenterTopFlex();
        width: 100%;

        .cardInformation {
            background: $white;
            width: 90%;
            border-radius: 20px;
            padding: 15px 10px;
            @include columnCenterTopFlex();
            text-align: center;
            color: $color-secondary;

            h2 {
                color: $color-quaternary;
                font-weight: bold;
                font-size: 20px;
                margin-bottom: 10px;
            }

            p {
                font-weight: bold;
                margin-bottom: 10px;

                span {
                    text-transform: uppercase;
                    margin-right: 10px;
                }
            }

            .achieve {
                background: $gradient-low-opacity;
                width: 90%;
                border-radius: 20px;
                padding: 10px;

                p {
                    font-weight: 600;
                }

                .reward {
                    @include centerFlex();
                    margin: 0 10px;

                    div {
                        @include centerFlex();

                        img {
                            @include minAndMaxSize(30px, 30px);
                        }

                        span {
                            font-weight: bold;
                            margin-left: 7px;
                        }
                    }
                }
            }
        }

        .choices {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            width: 90%;
            margin: 10px 0;
            flex-wrap: wrap;

            section {
                min-width: initial;
                max-width: calc(95% / 3);
                height: auto;
                margin: 10px 0px;

                @media (max-width: 1020px) {
                    max-width: calc(95% / 2);
                }
                @media (max-width: 700px) {
                    max-width: 100%;
                    width: 100%;
                }
            }

            @media (max-width: 700px) {
                @include columnCenterTopFlex();
            }

            .contentCard {
                @include columnCenterTopFlex();
                justify-content: space-between;
                height: 100%;
                .edtech {
                    @include columnCenterTopFlex();
                    text-align: center;
                    color: $color-secondary;

                    .header {
                        @include columnCenterTopFlex();
                        margin-top: 10px;

                        h4 {
                            margin-top: 10px;
                            text-transform: uppercase;
                            font-weight: bold;
                            margin-bottom: 5px;
                        }
                    }

                    .content {
                        .tags {
                            @include centerFlex();
                            flex-wrap: wrap;
                            li {
                                margin: 15px;
                                font-weight: 600;
                                position: relative;
                                font-size: 15px;
                                cursor: initial;

                                &::before {
                                    content: '';
                                    position: absolute;
                                    left: -10px;
                                    top: 7px;
                                    @include minAndMaxSize(7px, 7px);
                                    background: $color-secondary;
                                    border-radius: 50%;
                                }
                            }
                        }

                        .textInfo {
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 3;
                            overflow: hidden;
                            margin: 10px;
                        }
                    }
                }
                .btn {
                    @include columnCenterTopFlex();
                    width: 100%;
                    margin-top: 10px;
                    margin-bottom: 20px;
                    padding: 0 5px;

                    button {
                        color: $color-quaternary;
                        text-transform: initial;

                        i {
                            color: $color-secondary;
                        }

                        &:hover {
                            color: $color-secondary;

                            i {
                                color: $color-quaternary;
                            }
                        }
                    }
                    .price {
                        @include priceStatus();
                    }
                }
            }
        }
    }
}

.contentModal {
    @include columnCenterTopFlex();
    justify-content: space-between;
    height: auto;
    min-height: 340px;
}
