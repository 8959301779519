@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.card {
    background: #dddee3;
    @include columnCenterFlex;
    padding: 15px;
    border-radius: $radius-mid;

    .logo {
        font-size: 50px;
        color: $color-secondary;
    }

    .content {
        color: $color-secondary;
        font-size: 17px;
        text-align: center;
        margin: 10px 0;

        p {
            margin: 10px 0;
        }

        span {
            font-weight: bold;
        }
    }
}

.unLock {
    background: $gradient-low-opacity-left;
}