@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.main {
    @include gridMainPage;

    .container {
        @include columnCenterTopFlex;
        width: 100%;
    }

    .col {
        @include columnCenterTopFlex;
        width: 100%;

        .recap {
            @include centerFlex;
            width: 100%;

            section,
            > div {
                width: 90% !important;
            }
        }
    }

    @media (max-width: 1020px) {
        @include columnCenterTopFlex;

        .col {
            flex-direction: row;
            width: 90%;
            align-items: flex-start;

            section {
                min-width: auto;
            }
        }
    }

    @media (max-width: 800px) {
        .col {
            @include columnCenterTopFlex;
            width: 100%;
        }
    }
}
