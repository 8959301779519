@import '../../shared/style/index.scss';

.container {
    background: $gradient-opacity;
    @include centerFlex;
    justify-content: flex-start;
    height: max-content;
    padding: 10px;
    border-radius: 10px;
    margin: 10px 0;
    width: 100%;
    .img {
        border: 2px solid $color-secondary;
        background: $white;
        @include centerFlex();
        border-radius: 20px;
        @include minAndMaxSize(100px, 100px);
        overflow: hidden;

        img {
            @include minAndMaxSize(100px, 100px);
            object-fit: cover;
        }
    }

    .content {
        @include columnCenterTopFlex;
        align-items: flex-start;
        height: 100%;
        font-weight: 500;
        margin-left: 10px;

        .state {
            background: $bg-grey-opacity;
            border-radius: 10px;
            width: fit-content;
            padding: 0 5px;
        }

        p {
            margin: 5px 0;

            span {
                word-break: break-all;
            }
        }
    }
}
