@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.page {
    background: $bg-page;
    min-height: 100vh;
    padding-bottom: 20px;

    @media (max-width: 1020px) {
        padding-bottom: 90px;
    }

    .container {
        .profil {
            @include columnCenterTopFlex();
            margin: auto;
            max-width: 1400px;

            form {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 0px;
                grid-row-gap: 0px;
                width: 100%;

                .space {
                    width: 120px;
                }

                .field {
                    @include columnCenterTopFlex();
                    align-items: flex-start;
                    width: max-content;
                    margin: 10px;
                    position: relative;
                    width: 90%;

                    label {
                        font-weight: 500;
                    }

                    input,
                    select {
                        border-radius: 50px;
                        width: 100%;
                        padding: 5px;
                        padding-left: 10px;
                        font-size: 16px;
                        background: $gradient-low-opacity;
                        color: $color-secondary;
                        font-weight: 500;
                        border: none;
                        margin: 5px 0;

                        &:focus {
                            outline-color: $color-quaternary;
                            outline-width: 1px;
                        }
                    }

                    .backdrop {
                        position: fixed;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        display: none;
                        overflow: auto;
                        z-index: 400;
                    }
                    .listCity {
                        @include columnCenterTopFlex();
                        width: 90%;
                        padding: 2px;
                        position: absolute;
                        box-shadow: $shadow;
                        background: $white;
                        border-radius: 10px;
                        z-index: 410;
                        top: 60px;

                        span {
                            cursor: pointer;
                            margin: 2px 0;
                        }
                    }
                }
            }

            button {
                margin: 20px 0 10px 0;
                color: $color-quaternary;
                &:hover {
                    color: $color-secondary;
                }
            }

            .updateProfile {
                margin: 10px 0;
                p {
                    outline-color: $color-quaternary;
                }
            }

            @media (max-width: 700px) {
                section {
                    div {
                        &:first-child {
                            flex-direction: column;
                            text-align: center;
                        }
                    }
                }
                form {
                    @include columnCenterTopFlex();
                    margin-top: 80px;
                    width: 100%;
                    flex-wrap: nowrap;
                    justify-content: center;
                    .space {
                        width: 0;
                    }
                    .field {
                        align-items: center;
                        width: 100%;

                        input,
                        select {
                            max-width: 90%;
                        }

                        label {
                            align-self: flex-start;
                            margin-left: 20px;
                        }
                    }
                }
            }
        }

        .cardSection {
            @include centerFlex();
            width: 100%;
            margin: auto;
            max-width: 1400px;

            section {
                width: 90%;
                min-width: auto;
                max-width: 1400px;

                @media (max-width: 470px) {
                    width: 90%;
                }
            }

            .content {
                padding: 10px;
                display: flex;

                h3 {
                    font-weight: bold;
                    font-size: 18px;
                    text-transform: uppercase;
                    margin-bottom: 20px;
                    color: $color-secondary;
                }

                .description {
                    font-weight: 500;
                    color: $color-secondary;
                    margin-bottom: 10px;
                }

                .recommendation {
                    @include columnCenterTopFlex();
                    align-items: flex-start;
                    label {
                        color: $color-secondary;
                        font-weight: bold;
                    }

                    input {
                        border-radius: 50px;
                        width: 80%;
                        padding: 5px;
                        padding-left: 15px;
                        font-size: 16px;
                        background: $gradient-low-opacity;
                        color: $color-secondary;
                        font-weight: 500;
                        border: none;
                        margin: 10px 0;

                        &:focus {
                            outline-color: $color-tertiary;
                            outline-width: 1px;
                        }
                    }

                    button {
                        color: $color-quaternary;
                        margin-top: 10px;

                        &:hover {
                            color: $color-secondary;
                        }

                        &:focus {
                            border: 2px solid $color-quaternary;
                        }
                    }
                }

                .showRecommendationList {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 15px;
                    margin-left: 10px;
                    width: 40%;
                    height: fit-content;

                    span {
                        border: 2px solid transparent;
                        background: $border-gradient;
                        margin: 10px;
                        padding: 4px 25px;
                        border-radius: 10px;
                        text-transform: capitalize;
                        font-weight: 500;
                        height: fit-content;
                    }
                }

                @media (max-width: 1020px) {
                    flex-direction: column;

                    .showRecommendationList {
                        width: auto;
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
