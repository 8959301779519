@import '../../../../../shared/style/index.scss';

tr {
    display: flex;
    align-items: center;
    margin: 15px;
    .name {
        font-weight: bold;
        padding-left: 20px;
    }
    td {
        text-align: center;
        vertical-align: middle;
        width: calc(100% / 2);
        height: 30px;
        display: flex;
        align-items: center;
        &:nth-child(2) {
            @include centerFlex;
            padding-right: 20px;

            @media (max-width: 500px) {
                text-align: initial;
            }
        }
        .switch {
            position: relative;
            @include centerFlex;
            margin: 0 15px;
            height: 30px;
            width: 60px;

            input {
                appearance: none;
            }
            .checked {
                background: $blue-gradient;
                border: 1px solid $color-secondary;

                &::before {
                    transform: translateX(30px);
                    background: $color-quaternary;
                }
            }
            .unChecked {
                background: $color-error;
                color: $white;
                border: 1px solid $color-error;
            }
            div {
                cursor: pointer;
                position: absolute;
                top: 0;
                left: 0;
                margin-left: auto;
                margin-right: auto;
                font-weight: 600;
                width: 60px;
                height: 30px;
                @include centerFlex;
                span {
                    border-radius: 5px;
                    width: 100%;
                    height: inherit;
                    color: $color-secondary;
                    transition: transform 0.2s;
                    @include centerFlex;
                    border-radius: 24px;

                    &::before {
                        position: absolute;
                        content: '';
                        height: 24px;
                        width: 24px;
                        left: 3.3px;
                        bottom: 3px;
                        background: white;
                        transition: 0.3s;
                        border-radius: 50%;
                    }
                }
            }
        }

        i {
            color: $color-secondary;
            cursor: pointer;
        }

        .subModal {
            display: none;
            background: $blue-gradient;
            position: absolute;
            color: $white;
            top: 0;
            left: 0;
            border-radius: 30px 30px 0px 0px;
            width: 100%;
            min-height: 200px;
            @include columnCenterTopFlex;
            animation: slideBottom 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

            .header {
                position: relative;
                background-color: $color-quaternary;
                width: 100%;
                text-align: center;
                border-radius: 30px 30px 0 0;

                h4 {
                    font-weight: bold;
                    margin: 15px;
                    color: $color-tertiary;
                }

                .closeModal {
                    @include centerFlex;
                    position: absolute;
                    top: 10px;
                    left: 15px;
                    background: $blue-gradient;
                    height: 35px;
                    width: 35px;
                    border-radius: 50%;
                    font-size: 27px;
                    font-weight: 700;
                    cursor: pointer;
                    text-align: center;

                    &:hover {
                        transform: scale(1.05);
                    }

                    span {
                        transform: translateY(-3px);
                    }
                }
            }

            table {
                margin-top: 10px;
            }
        }
    }
    @media (max-width: 600px) {
        margin: 15px 0;
        .name {
            padding-left: 10px;
        }
    }
}

@keyframes slideBottom {
    0% {
        transform: translateY(-30px);
        opacity: 0.1;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}
