@import '../../../shared/style/index.scss';

.card {
    display: flex;
    background: $blue-gradient;
    color: $white;
    border-radius: $radius-main;
    padding: 25px;
    width: 90%;

    .logo {
        @include centerFlex;
        @include borderGradient;
        min-height: 100px;
        height: 100px;
        min-width: 100px;
        border-radius: $radius-mid;
        margin-right: 25px;

        img {
            min-height: 80px;
            height: 80px;
            min-width: 80px;
            filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.4));
        }
    }

    .content {
        @include columnCenterTopFlex;
        align-items: flex-start;
        .title {
            color: $color-primary;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 20px;
        }

        .information {
            @include centerFlex;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin: 10px 0;
            transform: translateX(-7px);

            .containerIconTime {
                background: $border-gradient-bg-blue;
                @include centerFlex;
                background-color: transparent;
                border-radius: 20px;
                padding: 3px;
                border: 2px solid transparent;
                img {
                    width: 25px;
                    height: 25px;
                    min-width: 25px;
                    min-height: 25px;
                }
            }

            .time {
                span {
                    margin: 0 5px;
                }
            }

            .stars {
                background: $gradient;
                border-radius: 10px;
                color: $color-secondary;
                @include centerFlex;
                padding: 4px 10px;
                margin: 0 10px;
                font-weight: 500;

                img {
                    width: 20px;
                    height: 20px;
                    min-width: 20px;
                    min-height: 20px;
                    transform: scaleX(-1);
                    margin-right: 5px;
                }
            }
        }
        .description {
            font-weight: 500;
        }
    }

    @media (max-width: 650px) {
        flex-direction: column;
        align-items: center;
        .logo {
            width: max-content;
            margin-bottom: 20px;
        }

        .content {
            .information {
                margin: 20px 0;
                .stars {
                    margin: 5px;
                }
            }
        }
    }

    @media (max-width: 470px) {
        width: 100%;
    }
}
