// GENERIC
$bg-page: #e7f9fd;
$bg-opacity: rgba(0, 0, 0, 0.233);
$bg-blue-opacity: #17243fa6;
$bg-blue-hight-opacity: #17243f38;
$bg-grey: #eee;
$bg-disabled: #ccc;
$bg-grey-opacity: rgba(128, 128, 128, 0.4);
$bg-skeleton-light: rgba(255, 255, 255, 0.13);
$bg-skeleton-dark: rgba(0, 0, 0, 0.11);

// MAIN COLOR
$color-primary: #21bbee;
$color-primary-low-opacity: #21bbee40;
$color-secondary: #17243f;
$color-tertiary: #1c2057;
$color-quaternary: #16f7b4;
$color-quaternary-low-opacity: #16f7b440;
$color-dark-opacity: #17243fb0;
$white: #ffffff;
$color-error: rgb(255, 57, 57);
$soft-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
$shadow: rgba(146, 146, 146, 0.9) 0px 1px 6px 1px;
$white-shadow: rgba(255, 255, 255, 0.4) 0px 2px 20px 1px;
$white-shadow-blur: rgba(255, 255, 255, 0.4) 0px 2px 25px 5px;
$soft-shadow-content: 0 0 10px 0 rgba(0, 0, 0, 0.2);

// GRADIENT FX
$gradient: linear-gradient(to right, #16f7b4, #21bbee);
$gradient-top: linear-gradient(to top, #16f7b4, #21bbee);
$gradient-opacity: linear-gradient(to right, #16f7b465, #21bbee65);
$gradient-mid-opacity: linear-gradient(to right, #16f7b4e4, #21bbeea4);
$gradient-low-opacity: linear-gradient(to right, $color-quaternary-low-opacity, #21bbee40);
$gradient-low-opacity-left: linear-gradient(to left, #16f7b440, #21bbee40);
$blue-gradient: linear-gradient(to right, $color-tertiary, $color-secondary);
$blue-gradient-low-opacity: linear-gradient(to right, #1c2057e0, #17243fe0);
$blue-gradien-top: linear-gradient(to bottom, $color-tertiary, $color-secondary);
$blue-primary-gradient: linear-gradient(to top, $color-secondary, $color-primary);
$gradient-white: linear-gradient(to right, $white, $white);
$gradient-blue-secondary: linear-gradient($color-secondary, $color-secondary);

// BORDER FX
$border-basic: $gradient padding-box, $gradient-blue-secondary border-box;
$border-gradient: linear-gradient($white, $white) padding-box, $gradient border-box;
$border-gradient-bg-blue: linear-gradient($color-secondary, $color-secondary) padding-box, $gradient border-box;

// BORDER RADIUS
$radius-main: 20px;
$radius-mid: 15px;
$radius-min: 10px;
$radius-round: 50%;

//Z-INDEX
$zindex-backdrop: 4999;
$zindex-modal: 5000;

// -- BREAKPOINTS -- \\
$x-small-breakpoints: 340px;
$small-breakpoints: 450px;
$medium-breakpoints: 770px;
$x-medium-breakpoints: 690px;
$large-breakpoints: 1024px;
$x-large-breakpoints: 1500px;

//  -- FONT -- \\
$small-font: 14px;
$normal-font: 16px;
$medium-font: 18px;
$large-font: 20px;
$x-large-font: 25px;
$xx-large-font: 30px;

// -- SIZE -- \\
$x-small: 5px;
$small: 10px;
$medium: 15px;
$large: 20px;
$x-large: 25px;

// -- SHADOWS -- \\
$small-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
