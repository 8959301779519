@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.main {
    background: #e7f9fd;
    @include gridMainPage();

    .catalogue {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;
        width: 100%;

        .experiencesCard {
            @include flex();
            align-items: flex-start;
            background-repeat: repeat-y;
            background-size: contain;
            background-color: $color-secondary;
            width: 90%;
            border-radius: 20px;
            color: white;
            padding: 20px;
            padding-bottom: 0;
            margin: 15px 0;
            @media (min-width: 1500px) {
                background-size: cover;
            }

            &Image {
                @include minAndMaxSize(40%, 100%);
                @include flex();
                align-items: flex-end;
                img {
                    width: 100%;
                    max-height: 215px;
                }

                @media (max-width: 1300px) {
                    @include minAndMaxSize(50%, 100%);
                }
            }

            &Content {
                margin-left: 20px;
                .exclu {
                    color: $color-quaternary;
                    border: 1px solid $color-quaternary;
                    width: fit-content;
                    padding: 5px 10px;
                    border-radius: 5px;
                    font-size: 14px;
                }

                h3 {
                    color: $color-quaternary;
                    font-size: 19px;
                    font-weight: bold;
                    margin: 15px 0;
                }

                .button {
                    margin: 20px 0;

                    &:hover {
                        @include outline($color: $color-quaternary, $radius: 20px, $border-width: 2px);
                    }
                }
            }
            @media (min-width: 1020px) and (max-width: 1200px) {
                @include columnCenterTopFlex();
                &Image {
                    @include minAndMaxSize(100%, 200px);
                    margin-bottom: 20px;
                    align-items: center;

                    img {
                        max-height: 200px;
                    }
                }

                button {
                    margin: 20px auto;
                }
            }
            @media (max-width: 700px) {
                @include columnCenterTopFlex();
                &Image {
                    @include minAndMaxSize(100%, 200px);
                    margin-bottom: 20px;
                    align-items: center;

                    img {
                        max-height: 200px;
                    }
                }

                button {
                    margin: 20px auto;
                }
            }
        }

        .BO {
            @include columnCenterTopFlex();
            background-color: $color-secondary;
            background-repeat: repeat-y;
            background-size: contain;
            width: 90%;
            border-radius: 20px;

            @media (min-width: 1700px) {
                background-size: cover;
            }

            .contentBO {
                @include columnCenterTopFlex();
                padding: 10px;

                .rewards {
                    @include flex();
                    justify-content: flex-end;
                    width: 100%;
                    margin-right: 50px;

                    img {
                        @include minAndMaxSize(30px, 30px);
                    }

                    span {
                        color: $color-quaternary;
                        margin-left: 5px;
                    }
                }

                .descriptionBO {
                    @include columnCenterTopFlex();

                    h4 {
                        color: $color-quaternary;
                        font-weight: 600;
                        font-size: 20px;
                        margin: 10px 0;
                    }

                    img {
                        @include minAndMaxSize(60px, 60px);
                    }

                    p {
                        color: white;
                        text-align: center;
                        padding: 0 25px;
                    }
                }
            }
            button {
                background: $gradient;
                text-transform: initial;
                margin: 20px;
            }
        }
        .cardInfo {
            width: 95%;
        }

        .isMobile {
            display: none;

            @media (max-width: 1020px) {
                display: block;
            }
        }
    }

    .col {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        margin-top: 10px;
        width: 100%;

        .cardInfo {
            width: 95%;
        }

        .cardLink {
            width: 95%;
            @media (max-width: 1020px) {
                order: 5;
            }
        }

        .topVisions {
            @include centerFlex;
            width: 100%;
        }

        .exp {
            @include centerFlex;

            @media (max-width: 470px) {
                section {
                    width: 100%;
                }
            }
        }

        .isMobile {
            display: none;

            @media (min-width: 1021px) {
                display: block;
            }
        }
    }

    @media (max-width: 1020px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 80px;

        .catalogue {
            width: 100%;
        }

        .col {
            width: 90%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-items: center;
            align-items: flex-start;
        }
    }

    @media (max-width: 1019px) {
        .col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    @media (max-width: 660px) {
        .col {
            width: 100%;
        }
    }
}
