@import '../../../shared/style/index.scss';

.skeleton {
    overflow: hidden;

    .rectangular {
        @include skeleton();
    }

    .circular {
        @include skeleton($width: 50px, $radius: 50%);
    }

    .text {
        @include skeleton($height: 20px);
    }
}
.parent {
    background: $blue-gradient;
    margin: 10px 0;

    & > div {
        &::after {
            animation: none;
        }
    }
}

.lightBg {
    background: $color-quaternary;
    .rectangular,
    .circular,
    .text {
        background-color: $bg-skeleton-dark;
    }
}

.pulse {
    animation: 1.5s ease-in-out 0.5s infinite normal none backgroundPulse running;
}

.wave {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        inset: 0;
        transform: translateX(-100%);
        background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.08), transparent);
        animation: backgroundWave 1.6s linear 0.5s infinite none normal running;
    }
}

@keyframes backgroundPulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}

@keyframes backgroundWave {
    0% {
        transform: translateX(-100%);
    }
    50% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(100%);
    }
}
