@import '../../../shared/style/index.scss';

.text {
    @include outline();
    padding: 5px 10px;
    border-radius: 5px;
    width: fit-content;
    font-weight: 500;
}

.noBorder {
    &::after {
        border: none;
    }
}