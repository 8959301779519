@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.main {
    display: grid;
    grid-template-columns: 65% 35%;
    background: #e7f9fd;
    padding-bottom: 80px;
    min-height: 90vh;
    margin-top: 10px;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .card {
            display: flex;
            align-items: center;
            justify-content: space-around;
            background: $gradient;
            width: 90%;
            border-radius: 25px;
            margin-top: 15px;
            padding: 0 15px;

            .text {
                color: $color-secondary;
                text-transform: uppercase;
                text-align: center;
                color: $color-secondary;

                h2 {
                    font-weight: bold;
                    font-size: 20px;
                    margin-bottom: 5px;
                }
            }

            .img {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 200px;
                height: 200px;
            }
        }

        .isMobile {
            display: none !important;

            @media (max-width: 660px) {
                display: flex !important;

                button {
                    margin: 0;
                }
            }
        }

        .title {
            font-weight: bold;
            margin: 10px 0;
            margin-left: 20px;
            color: $color-secondary;
        }

        .content {
            display: grid;
            grid-template-columns: 50% 50%;
            justify-items: center;
            overflow-y: scroll;
            max-height: 400px;
            &::-webkit-scrollbar {
                background: $gradient-opacity;
                border-radius: 10px;
                width: 10px;
            }
            &::-webkit-scrollbar-thumb {
                background: $color-secondary;
                border-radius: 10px;
            }
            @media (max-width: 660px) {
                overflow: initial;
                max-height: max-content;
            }
        }

        .btn {
            display: flex;
            justify-content: center;
            margin: 20px 0px;
        }

        @media (max-width: 660px) {
            .content {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    .col {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @media (max-width: 1020px) {
        display: flex;
        flex-direction: column;
        align-items: center;

        .container {
            width: 100%;
        }
    }

    @media (max-width: 850px) {
        .container {
            .card {
                .text {
                    h2 {
                        font-size: 16px;
                    }
                }

                .img {
                    width: 150px;
                    height: 150px;
                }
            }
        }
    }
    @media (max-width: 660px) {
        .container {
            .card {
                flex-direction: column-reverse;
                .text {
                    margin-bottom: 15px;
                    padding: 10px;
                    h2 {
                        font-size: medium;
                    }
                }

                .img {
                    width: 150px;
                    height: 150px;
                }
            }
        }
    }
}
