@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.page {
    @include pageInit();

    .main {
        transition: all 0.2s;
        padding: 20px 40px;
        @include resolution(x-desktop) {
            @include columnCenterTopFlex();
            align-items: stretch;
        }
        @include resolution(mobile) {
            padding: 20px;
            padding-bottom: 90px;
        }
        @include resolution(x-mobile) {
            padding: 20px 5px;
            padding-bottom: 90px;
        }

        .matchs {
            background: $white;
            margin-bottom: $large;
            padding: 0;
            overflow: hidden;

            h2 {
                @include title($color: $color-secondary);
            }

            h3 {
                padding-left: $small;
            }

            iframe {
                margin: $small;
                min-height: 500px;
                @include borderGradient($white, 2px, $color-quaternary-low-opacity, $color-primary-low-opacity);
                border-radius: $medium;
                width: auto;
            }
        }

        .cozy {
            @include flex();
            flex-direction: row;
            justify-content: space-between;

            &Information {
                @include columnCenterTopFlex();
                align-items: flex-start;
                justify-content: space-between;
                h2 {
                    @include title($color: $color-secondary);
                }

                .description {
                    font-weight: 500;
                    margin: $medium 0;
                }
                button {
                    font-weight: 500;
                    text-transform: initial;
                }
            }

            img {
                @include minAndMaxSize(150px, 150px);
            }

            @include resolution(mobile) {
                @include columnCenterTopFlex();
                flex-direction: column-reverse;

                &Information {
                    align-items: center;
                    text-align: center;
                }
            }
        }

        .noIdentifier {
            @include centerFlex();
            gap: $small;
            background: $gradient-white padding-box, $gradient border-box;
            border: 2px solid transparent;
        }
    }
}
