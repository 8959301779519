@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.main {
    display: grid;
    grid-template-columns: 65% 35%;
    margin-top: 30px;
    padding-bottom: 80px;

    .container {
        display: grid;
        // grid-template-columns: 50% 50%;
        justify-content: center;
        justify-items: center;

        section {
            height: auto;
            div {
                overflow: visible;
            }
        }

        .card {
            background: $gradient-opacity;
            padding: $small;
            margin: 0 $small;
            text-align: center;

            .header {
                background: $bg-opacity;
                border-radius: $small;
                @include centerFlex();
                padding: 2px 0;
                margin-bottom: $x-small;

                img {
                    @include minAndMaxSize(40px, 40px);
                }
            }
        }

        .cardRow {
            flex-direction: row;
            align-items: center;
            padding: 0;
            overflow: hidden;
            min-height: auto;

            .header {
                border-radius: 0;
                margin-bottom: 0;
                margin-right: $small;
                padding: $small;
                img {
                    @include minAndMaxSize(70px, 50px);
                }
            }

            @include resolution(mobile) {
                margin: $small 0;
            }
        }

        .parcoursup {
            display: grid;
            grid-template-columns: 50% 50%;
            margin-bottom: $large;

            @include resolution(mobile) {
                @include columnCenterTopFlex();

                .card {
                    width: 100%;
                    margin: $small $x-small;
                }
            }
        }
    }

    .col {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .linkCard {
            width: 90%;
        }
    }

    @media (max-width: 1300px) {
        grid-template-columns: auto;
        justify-content: center;
        justify-items: center;
    }
    @media (max-width: 900px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            section {
                margin-bottom: 35px;
            }
        }
    }
}
