@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.tmp {
    color: white;
}

.active {
    font-weight: bold !important;
    position: relative;
    &::after {
        content: '';
        position: absolute;
        height: 3px;
        width: 110%;
        background: $gradient;
        bottom: -10px;
        left: -5px;
    }
}

.discoverContainer {
    background: $blue-gradient;
    color: white;
    @include centerFlex;
    flex-wrap: wrap;
    min-height: 500px;
    // padding-top: 40px;
    // padding-bottom: 20px;

    .center {
        display: flex;
        margin: 0 auto;

        @media (max-width: 1000px) {
            flex-wrap: wrap;
            padding: 40px 0;
        }

        .discover {
            width: 100%;
            padding: 0 80px;
            min-height: 300px;
            position: relative;

            .exclu {
                color: $color-quaternary;
                margin-bottom: 20px;

                span {
                    font-weight: bold;

                    span {
                        font-weight: 500 !important;
                    }
                }
            }

            h1 {
                color: $white;
                font-size: 30px;
                font-weight: 600;
                position: relative;
                margin-bottom: 30px;

                @media (min-width: 700px) and (max-width: 1000px) {
                    width: 80%;
                }

                &::after {
                    content: '';
                    position: absolute;
                    height: 10px;
                    width: 100%;
                    max-width: 605px;
                    background: $gradient;
                    bottom: -22px;
                    left: 0px;
                }
            }

            .description {
                color: white;
                padding-top: 20px;
                font-size: 17px;
                width: 100%;
                margin-bottom: 40px;
            }

            @media (max-width: 720px) {
                padding-bottom: 80px;
            }

            @media (max-width: 500px) {
                text-align: center;
                padding: 0 10px;
                padding-bottom: 80px;

                h1 {
                    font-size: 26px;
                }

                p {
                    font-size: 18px;
                }

                button {
                    margin: auto;
                }
            }
        }

        .compass {
            @include centerFlex;
            align-items: flex-end;
            width: 100%;
            .image {
                height: 330px;
                // margin-top: -90px;
                width: 90%;
                background-position: center;
                background-image: url('../../../img/sucess.png');
                background-repeat: no-repeat;
                background-size: contain;

                @media (max-width: 700px) {
                    margin-top: -60px;
                }
            }
        }
    }
}

.pathContainer {
    background: $blue-gradient;
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    padding-bottom: 500px;

    @media (max-width: 700px) {
        padding-bottom: 200px;
    }

    h1 {
        font-size: 30px;
        width: 100%;
        text-align: center;
        padding: 60px 0;
        color: $white;
        font-weight: 600;
    }

    .path {
        position: absolute;
        width: 740px;
        top: 15%;
        clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 0% 90%);

        svg {
            fill: $color-primary;
        }

        @media (max-width: 1150px) {
            width: 640px;
        }

        @media (max-width: 700px) {
            display: none;
        }
    }

    button {
        position: absolute;
        bottom: 130px;
        margin: auto;

        @media (max-width: 700px) {
            bottom: 100px;
        }
    }

    .content {
        width: 80%;

        @media (max-width: 1150px) {
            width: 90%;
        }

        .stepContainer {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            height: 200px;
            padding-top: 330px;
            flex-wrap: wrap;

            &:nth-child(even) {
                justify-content: flex-start;
            }

            &:nth-of-type(1) {
                padding-top: 50px;
            }

            &:nth-of-type(2) {
                padding-top: 230px;
            }

            @media (max-width: 1150px) {
                padding-top: 290px;

                &:nth-of-type(2) {
                    padding-top: 150px;
                }

                &:nth-of-type(3) {
                    padding-top: 220px;
                }
            }
        }

        @media (max-width: 700px) {
            display: flex;
            flex-direction: column;
            .stepContainer {
                justify-content: center !important;
                padding-top: 0px !important;
                margin-top: 80px;
                height: max-content;
                &:first-child {
                    margin-top: 0px;
                }
            }
        }
    }
}

.newFeature {
    @include centerFlex;
    min-height: 400px;

    .card {
        background: $gradient;
        border-radius: 20px 0;
        @include centerFlex;
        flex-direction: column;
        padding: 20px 0px;
        transform: translateY(-70px);
        text-align: center;
        width: 90%;
        max-width: 900px;

        .illustration {
            img {
                height: 220px;
            }
        }

        .contentCard {
            width: 80%;

            h3 {
                font-weight: bold;
                font-size: 30px;
                margin-bottom: 15px;
            }

            p {
                margin-bottom: 10px;
                font-size: 17px;
                span {
                    font-weight: bold;
                }
            }
        }

        button {
            color: $white;
            width: auto;
        }

        @media (max-width: 500px) {
            width: 95%;
        }
        @media (max-width: 400px) {
            width: 100%;
        }
    }
}

footer {
    background: $blue-gradient;

    .footerContent {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 0 auto;

        @media (max-width: 580px) {
            padding-top: 30px;
        }
    }
    .footerText {
        color: $white;
        width: calc(33.33% - 40px);
        text-align: center;
        margin: 20px;
        font-weight: bold;

        a {
            color: $white;
            text-decoration: none;
        }

        @media (max-width: 580px) {
            width: calc(100% - 20px);

            &:last-child {
                border-top: solid 2px #1dbbee;
                width: 100%;
                padding-top: 15px;
            }
        }
    }
}
