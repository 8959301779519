@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.page {
    padding-bottom: 80px;
    @include pageInit();

    .main {
        display: flex;
        .container {
            width: 65%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .col {
            width: 35%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .noteContainer {
                display: flex;
                align-items: center;
                flex-direction: column;
                width: 100%;
            }

            .linkCard {
                width: 95%;
            }
        }

        @media (min-width: 850px) and (max-width: 1020px) {
            flex-direction: column;
            align-items: center;

            .container {
                width: 95%;
            }

            .col {
                width: 90%;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 0px;
                grid-row-gap: 0px;
                justify-items: center;
                align-items: flex-start;

                div {
                    &:first-child {
                        grid-area: 1 / 1 / 2 / 2;
                    }
                }

                section {
                    width: 90%;
                    margin-top: 10px;

                    &:nth-child(0n + 2) {
                        grid-area: 1 / 2 / 2 / 3;
                    }
                }

                .linkCard {
                    order: 5;
                    grid-area: 2 / 1 / 3 / 3;
                    display: flex;
                }
            }
        }

        @media (max-width: 849px) {
            flex-direction: column;
            align-items: center;

            .container {
                width: 90%;
            }

            .col {
                flex-direction: column;
                align-items: center;
                width: 90%;

                section {
                    width: 90%;
                    margin: 10px;
                }
            }
        }

        @media (max-width: 470px) {
            .container {
                width: 100%;
            }

            .col {
                width: 100%;
            }
        }
    }
}
