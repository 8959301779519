@import '../../../shared/style/index.scss';

.container {
    @include flex();
    justify-content: space-between;
    align-items: flex-start;
    background: transparent;
    padding: 50px;
    min-height: 60vh;

    @media (max-width: 500px) {
        padding: 20px !important;
    }
}

.blue {
    background: $blue-gradient;
}

.white {
    background: $white;
}

.bgImage {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    background-color: $color-tertiary;
}
