@import '../../../shared/style/index.scss';

.card {
    background: $gradient-low-opacity;
    @include columnCenterTopFlex();
    width: auto;
    max-width: 45%;
    margin: 10px;
    border-radius: 20px;
    padding: 15px;
    position: relative;

    .header {
        margin: 10px 0;
        h3 {
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .info {
            i {
                background: $gradient;
                -webkit-background-clip: text;
                -moz-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-right: 5px;
            }
        }
    }

    .select {
        @include centerFlex();
        @include minAndMaxSize(25px, 25px);
        border: 2px solid $color-secondary;
        background: $white;
        border-radius: 50%;
        position: absolute;
        cursor: pointer;
        top: 15px;
        right: 15px;
        overflow: hidden;

        img {
            object-fit: contain;
            padding: 2px;
        }
    }

    @media (max-width: 700px) {
        max-width: none !important;
        width: 100%;
    }
}

.active {
    background: $bg-disabled;

    .select {
        background: $blue-gradient;
    }
}

.request {
    max-width: 90%;
    background: $white;
    border: 2px solid $color-secondary;
    font-weight: 500;
    padding: 0;
    overflow: hidden;

    .content {
        @include columnCenterTopFlex();
        padding: 10px;
        .bilan {
            margin: 10px 0;

            .transmittedContainer {
                margin: 10px 0;
                text-transform: uppercase;

                span {
                    margin: 0 10px;
                    font-weight: 600;
                    padding: 5px 10px;
                    border-radius: 5px;
                }

                .transmitted {
                    background: $gradient-low-opacity;
                }
            }
        }
    }

    .bg {
        background: $blue-gradient;
        width: 100%;
        height: 25px;
    }
}

.contentModal {
    @include columnCenterTopFlex();
    min-height: 320px;
    justify-content: space-between;
    position: relative;

    .informationModal {
        @include columnCenterTopFlex();
        margin: 10px 0;
        text-align: center;

        h3,
        h4 {
            text-transform: uppercase;
            color: $white;
            font-weight: bold;
            font-size: 19px;
        }
        h4 {
            font-weight: 500;
            font-size: 16px;
            margin: 10px;
        }

        p {
            font-weight: 600;
            margin: 20px 0;
        }
    }

    .hook {
        width: calc(100% + 35px);
        position: absolute;
        top: 5%;

        @media (max-width: 550px) {
            top: 25%;
        }
        @media (max-width: 400px) {
            top: 35%;
        }
    }

    .btn {
        @include centerFlex();
        flex-wrap: wrap;
        gap: 10px;

        button {
            color: $white !important;
            width: 100px;
        }
    }
}
