@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.main {
    @include gridMainPage;
    background: #e7f9fd;

    @media (max-width: 1020px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .content {
        display: flex;
        justify-content: center;
        min-width: 80%;

        .btn {
            display: flex;
            justify-content: center;

            button {
                width: 250px;
            }
        }
    }

    .col {
        display: flex;
        flex-direction: column;
        align-items: center;

        .linkCard {
            width: 95%;
        }

        .exp {
            display: flex;
            justify-content: center;

            @media (max-width: 470px) {
                section {
                    width: 100%;
                }
            }
        }
    }
}
