@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.content {
    width: 90%;
    display: flex;
    justify-content: flex-start;
    background: $blue-primary-gradient;
    padding: 25px;
    border-radius: 20px;
    margin: 10px 0px;
    position: relative;

    .information {
        @include columnCenterTopFlex();
        align-items: flex-start;
        margin-left: 20px;

        .text {
            color: $white;
            font-weight: 600;
            min-height: 50px;
        }
    }

    @media screen and (max-width: 400px) {
        @include columnCenterTopFlex();

        .image {
            @include minAndMaxSize(80px, 80px);
        }

        .information {
            margin: 0;
            text-align: center;
            align-items: center;

            .text {
                margin: 10px 0;
            }
        }
    }
}

.blue {
    background: $blue-gradient;

    .information {
        .text {
            color: white;
        }
    }
}

.available::after {
    background: rgba(0, 0, 0, 0.747);
    content: 'Prochainement';
    color: white;
    font-weight: bold;
    position: absolute;
    width: 100%;
    // max-width: 250px;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 90;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
}

.full {
    width: 100%;
}
