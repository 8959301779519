@import '../../../shared/style/index.scss';

.container {
    width: auto;
    .title {
        @include flex();
        img {
            @include minAndMaxSize(60px, 60px);
        }
        h3 {
            @include title($size: 25px, $color: $color-secondary, $transform: initial);
            margin-left: 5px;
        }

        .darken {
            filter: brightness(0.2);
        }

        @media (max-width: 400px) {
            img {
                @include minAndMaxSize(50px, 50px);
            }
            h3 {
                @include title($size: 21px, $color: $color-secondary, $transform: initial);
                margin-left: 5px;
            }
        }
    }
    @media (max-width: 500px) {
        padding: 0 !important;
    }
}
