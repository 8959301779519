@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.modal {
    @include columnCenterTopFlex();
    justify-content: center;

    h2 {
        @include title($color: $color-secondary);
        text-align: center;
        margin-bottom: $medium;
    }
    &Content {
        display: flex;
        align-items: stretch;

        &Providers {
            width: calc(100% / 2);
            @include columnCenterTopFlex();
            height: 100%;
            &List {
                @include flex();
                justify-content: center;
                flex-wrap: wrap;

                img {
                    @include minAndMaxSize(60px, 60px);
                    margin: $x-small;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    background-color: white;
                    border-radius: $small;
                }
            }
            .serviceImport {
                @include minAndMaxSize(100px, auto);
                object-fit: contain;
                background-color: white;
                border-radius: $small;
            }

            p {
                font-weight: bold;
            }
        }

        i {
            font-size: 40px;
            color: $color-secondary;
            margin: $small;

            @include resolution(mobile) {
                font-size: 20px;
            }
        }
    }
    &Description {
        cursor: pointer;
        font-weight: 600;
        margin: $medium $x-small;
    }

    &HideDescription {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }

    &Table {
        table {
            width: 100%;
        }
    }

    .btn {
        margin: 0;
        margin-top: $large;
    }
}
