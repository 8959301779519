@import '../../../shared/style/index.scss';

.container {
    @include flex();
    margin: 20px 0;

    .info {
        margin-left: 10px;
    }

    @media (max-width: 400px) {
        @include columnCenterTopFlex();
        text-align: center;

        .info {
            margin: 10px 0;
        }
    }
}
