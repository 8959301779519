@import '../../../shared/style/index.scss';

.card {
    background: $white;
    box-shadow: $soft-shadow-content;
    border-radius: 25px;
    width: 45%;
    min-width: 40%;
    margin: 10px;
    height: auto;
    overflow: hidden;
    @include columnCenterTopFlex;
    justify-content: space-between;

    .container {
        width: 100%;
        .header {
            width: 100%;
            .img {
                width: 100%;
                min-height: 150px;
                img {
                    object-fit: cover;
                    @include minAndMaxSize(100%, 150px);
                    object-position: center;
                    background: $bg-blue-hight-opacity;
                }
            }

            .logo {
                display: flex;
                justify-content: center;
                margin-top: 5px;

                img {
                    height: 30px;
                }
            }
        }

        .content {
            @include columnCenterTopFlex;
            justify-content: space-evenly;
            width: 100%;

            img {
                @include minAndMaxSize(20px, 20px);
                margin-right: 5px;
            }

            .tags {
                width: 100%;
                @include centerFlex;
                justify-content: space-evenly;
                margin: 15px 0;
                flex-wrap: wrap;
                font-size: 14px;

                .mainTag {
                    display: flex;
                    align-items: center;
                    background: #cdd0d8;
                    border-radius: 10px;
                    padding: 5px 15px;
                    min-width: fit-content;
                    margin: 5px;
                    font-weight: 500;
                }

                .visionsStars {
                    display: flex;
                    align-items: center;
                    background: $gradient-opacity;
                    border-radius: 10px;
                    padding: 5px 15px;
                    min-width: fit-content;
                    margin: 5px;
                    font-weight: 600;
                }
            }
            .description {
                color: $color-secondary;
                text-align: center;
                font-weight: bold;
                padding: 0 25px;
                margin: 10px 0;
            }
        }
    }

    .bottomCard {
        @include columnCenterTopFlex();
        width: 100%;
        .btn {
            margin: 15px 0;

            button {
                font-size: 14px;
                margin: 0;
                border: none;
                padding: 5px 15px;
                text-transform: initial;
                color: $color-quaternary;

                i {
                    color: $color-secondary;
                }
                &:hover {
                    color: $color-secondary;

                    i {
                        color: $white;
                    }
                }
            }
        }
        .bg {
            height: 40px;
            width: calc(100% + 2px);
            transform: translateX(-1px);
            background: $gradient;
        }
    }

    @media (max-width: 800px) {
        width: 100%;
        // min-width: 280px;
    }
    @media (max-width: 400px) {
        margin: 13px 10px;
    }
}
