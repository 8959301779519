.container {
    .header {
        display: flex;
        justify-content: center;
        align-items: center;

        h2 {
            color: var(--v-blue);
            text-transform: uppercase;
            font-size: 25px;
            font-weight: bold;
            margin: 10px;

            span {
                color: var(--v-dark-blue);
                text-transform: lowercase;
            }
        }
    }

    .content {
        display: grid;
        grid-template-columns: 50% 50%;
        width: 100%;
        justify-content: center;
        justify-items: center;
        align-items: center;

        section {
            width: 90%;
            @media (max-width: 500px) {
                width: 100%;
            }
        }

        @media (max-width: 800px) {
            display: flex;
            flex-direction: column;
        }
    }

    .btn {
        display: flex;
        justify-content: center;
        
        button {
            width: 250px;
        }
    }
}