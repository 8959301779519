@import '../../../shared/style/index.scss';

.container {
    @include columnCenterFlex;
    text-align: center;
    padding: 20px;

    .header {
        img {
            min-width: 80px;
            min-height: 80px;
            width: 80px;
            height: 80px;
        }

        h3 {
            text-transform: uppercase;
            color: $color-primary;
            font-size: 18px;
            font-weight: bold;
            margin: 15px 0;
        }
    }

    .text {
        color: $color-secondary;
        font-weight: 500;

        @media (min-width: 1000px) and (max-width: 1180px) {
            height: 65px;
        }
    }

    button {
        margin-top: 20px;
    }
}