@import '../../../shared/style/index.scss';

.card {
    width: 100%;
    height: max-content;
    border-radius: 20px;
    overflow: hidden;
    background: $white;
    padding-bottom: 20px;
    margin: 10px 0;
    @include columnCenterTopFlex();

    .header {
        background: $blue-gradient;
        display: flex;
        justify-content: flex-start;
        color: $white;
        padding: 5px 10px 5px 25px;
        width: 100%;

        .title {
            @include flex();
            flex-wrap: wrap;
            h3 {
                @include title($color: $color-secondary, $size: 21px);
                margin-right: 10px;
            }
            span {
                font-size: 19px;
                margin: 3px;
            }
        }
        img {
            @include minAndMaxSize(45px, 45px);
            margin-right: 20px;
        }
    }

    .content {
        @include columnCenterFlex();
        width: 100%;

        .task {
            @include centerFlex();
            justify-content: space-between;
            width: 95%;
            background: $gradient-low-opacity;
            border-radius: 20px;
            margin: 10px 0;
            padding: 15px 8px;

            .information {
                display: flex;
                align-items: center;
                .logo {
                    @include minAndMaxSize(65px, 65px);
                    background: $white;
                    border-radius: 50%;
                    margin-right: 15px;
                    z-index: 1;
                    position: relative;

                    .check {
                        @include minAndMaxSize(21px, 21px);
                        position: absolute;
                        bottom: -2px;
                        right: -5px;
                        background: $white;
                        border: 1px solid $color-secondary;
                        z-index: 10;
                        border-radius: 50%;
                        padding: 3px;
                    }

                    .checked {
                        background: $blue-gradient;
                    }
                }

                .description {
                    display: flex;
                    flex-direction: column;
                    color: $color-secondary;

                    .taskName {
                        @include title($color: $color-secondary);
                    }

                    .descriptionText {
                        font-size: 18px;
                        font-weight: 500;
                    }
                }

                .taskCompleted {
                    opacity: 0.8;
                    .taskName {
                        text-decoration: line-through;
                    }
                }
            }

            .reference {
                display: flex;
                .reward {
                    @include centerFlex();
                    margin: 0 50px;

                    div {
                        @include centerFlex();

                        img {
                            @include minAndMaxSize(30px, 30px);
                        }

                        span {
                            font-weight: bold;
                            margin-left: 7px;
                        }
                    }
                }

                .accomplish {
                    margin: 10px;

                    .replay {
                        background: transparent;
                        color: $color-secondary;
                        border: 1px solid $color-secondary;
                    }
                }
            }
        }
    }
    .carousel {
        @media (max-width: 700px) {
            width: 100%;
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;
            scroll-padding: 10px;
            scrollbar-width: none !important;
            -ms-overflow-style: none !important;
            &::-webkit-scrollbar {
                display: none !important;
            }

            .task {
                margin: 10px 5px;
                @include columnCenterTopFlex();
                min-width: 90%;

                .information {
                    @include columnCenterTopFlex();
                    text-align: center;
                    margin: 5px;

                    .description {
                        margin: 10px 0;

                        .taskName {
                            margin: 5px 0;
                        }
                    }
                }

                .reference {
                    flex-direction: column;
                    .reward {
                        margin: 5px;
                    }

                    .accomplish {
                        margin: 10px 5px;
                    }
                }
            }
        }
    }
    .report {
        @include centerFlex();
        justify-content: flex-start;
        width: 95%;
        .gaugeContainer {
            @include columnCenterTopFlex();
            width: 100%;
            transition: all 0.5s;

            .gauge {
                border: 2px solid $color-secondary;
                width: 100%;
                height: 12px;
                border-radius: 50px;
                position: relative;
                margin-right: 10px;
                transition: all 0.2s;
                overflow: hidden;

                .meter {
                    background: $blue-gradient;
                    width: 0%;
                    height: 8px;
                    border-radius: 50px;
                    position: absolute;
                    left: 0px;
                    transition: width 0.15s;
                }
            }

            p {
                align-self: flex-end;
                color: $color-secondary;
                font-weight: bold;
                padding-right: 10px;
                margin-top: 2px;
            }
        }
        .total {
            @include centerFlex();
            @include borderGradient();
            border-radius: 5px;
            padding: 3px 20px;
            margin-left: 10px;
            div {
                @include centerFlex();
                margin: 0 5px;
                img {
                    @include minAndMaxSize(30px, 30px);
                    margin-right: 7px;
                }
            }
        }

        @media (max-width: 700px) {
            flex-direction: column;
            align-items: flex-start;

            .total {
                margin: 0;
            }
        }
    }
}

.cardFinished {
    padding: 10px;
    background: $gradient-low-opacity;

    .header {
        background: none;
        color: $color-secondary;
        padding: 0px;
        font-weight: bold;
        margin-bottom: 5px;

        h3 {
            margin: 0 !important;
            margin-right: 5px !important;
        }
    }

    .content {
        .task {
            background: none;
            width: 100%;
            padding: 0 5px;

            @media screen and (max-width: 550px) {
                @include columnCenterTopFlex();

                .information {
                    @include columnCenterTopFlex();

                    .description {
                        margin: 5px 0;
                    }
                }
            }

            .description {
                .taskName {
                    text-transform: capitalize !important;
                    font-size: 19px !important;
                    .descriptionText {
                        text-transform: initial;
                    }
                }
            }
        }
    }
}
