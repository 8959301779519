@import '../../shared/style/index.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0;

    .header {
        background: linear-gradient(to right, white, white) padding-box,
            linear-gradient(to right, #71f2b8, #21bbee) border-box;
        border: 3px solid transparent;
        border-radius: 10px;
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;

        img {
            border-radius: 10px;
        }
    }

    .text {
        text-align: center;
        width: 80%;
        margin-bottom: 10px;
        font-weight: 500;
        color: $color-secondary;

        span {
            text-transform: uppercase;
            font-weight: bold;
        }
    }

    .btn {
        margin-bottom: 20px;
        button {
            color: white;
            text-transform: uppercase;
            padding: 7px 15px;
        }
    }
}
