@use './variable' as var;

@mixin pageInit {
    background: var.$bg-page;
    min-height: 100vh;
    padding-bottom: 20px;

    @media (max-width: 1020px) {
        padding-bottom: 90px;
    }
}

@mixin mainInit($display: column) {
    padding: 20px 40px;
    transition: all 0.2s;
    @if $display == column {
        display: grid;
        grid-template-columns: 65% auto;
        gap: 30px;
        @include resolution(x-desktop) {
            @include columnCenterTopFlex();
            align-items: stretch;
        }
    } @else if $display == row {
        @include columnCenterTopFlex();
    }

    @include resolution(mobile) {
        padding: 20px;
        padding-bottom: 90px;
    }
    @include resolution(x-mobile) {
        padding: 20px 5px;
        padding-bottom: 90px;
    }
}

@mixin spacingMainContent($direction: column, $gap: $large) {
    display: flex;
    flex-direction: $direction;
    align-items: stretch;
    gap: $gap;
}

@mixin resolution($device) {
    @if $device == mobile {
        @media screen and (max-width: var.$small-breakpoints) {
            @content;
        }
    } @else if $device == x-mobile {
        @media screen and (max-width: var.$x-small-breakpoints) {
            @content;
        }
    } @else if $device == x-tablet {
        @media screen and (max-width: var.$x-medium-breakpoints) {
            @content;
        }
    } @else if $device == tablet {
        @media screen and (max-width: var.$medium-breakpoints) {
            @content;
        }
    } @else if $device == x-desktop {
        @media screen and (max-width: var.$large-breakpoints) {
            @content;
        }
    } @else if $device == desktop {
        @media screen and (max-width: var.$x-large-breakpoints) {
            @content;
        }
    }
}

@mixin flex {
    display: flex;
    align-items: center;
}

@mixin centerFlex {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin columnCenterTopFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin columnCenterFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@mixin gridMainPage {
    display: grid;
    grid-template-columns: 65% 35%;
    justify-content: center;
    justify-items: center;
    min-height: 91vh;
    padding-bottom: 80px;
}

@mixin gridTemplate($column: 2, $row: 2) {
    display: grid;
    grid-template-columns: repeat($column, 1fr);
    grid-template-rows: repeat($row, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

@mixin largeLinkCard {
    width: 95%;
    height: 225px;
    max-width: none;
    flex-direction: row;

    .logo {
        width: 150px;
        min-width: 150px;
        height: 150px;
        align-items: baseline;
        margin: 0 10px;

        img {
            height: 150px;
        }
    }

    .information {
        padding: 0 10px;
        .text {
            text-align: left;
        }

        .btn {
            justify-content: flex-start;
        }
    }
}

@mixin sliderArrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 200px;
    // height: 60%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    // bottom: 25%;
    cursor: pointer;
    font-size: 30px;
    color: #ffffff67;
    background: #17243f67;
    @media (max-width: 900px) {
        color: $white;
        background: $color-secondary;
    }
    &:hover {
        color: $white;
        background: $color-secondary;
    }
}

@mixin sliderArrowBilan {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 50px;
    position: absolute;
    top: 25%;
    cursor: pointer;
    font-size: 30px;
    color: #ffffff67;
    background: #17243f67;
    @media (max-width: 900px) {
        color: $white;
        background: $color-secondary;
    }
    &:hover {
        color: $white;
        background: $color-secondary;
    }
}

@mixin borderGradient(
    $bg-color: var.$white,
    $border-size: 2px,
    $border-color-one: var.$color-primary,
    $border-color-two: var.$color-quaternary
) {
    background: linear-gradient($bg-color, $bg-color) padding-box,
        linear-gradient($border-color-one, $border-color-two) border-box;
    border: $border-size solid transparent;
}

@mixin borderLinearGradient($border: var.$gradient, $background: var.$gradient-white) {
    background: $background padding-box, $border border-box;
    border: 2px solid transparent;
}

@mixin animationSlideRightPage {
    animation: slideRight 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

    @keyframes slideRight {
        0% {
            transform: translateX(50%);
        }
        100% {
            transform: translateX(0);
        }
    }
}

@mixin animationSlideLeftPage {
    animation: slideLeft 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

    @media (max-width: 700px) {
        animation: slideLeftMobile 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    @keyframes slideLeft {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-80%);
        }
    }
    @keyframes slideLeftMobile {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-100%);
        }
    }
}

@mixin animationPulseShadow($scale: 1.02, $color-shadow: var.$white, $duration: 2s) {
    animation-name: pulseBtn;
    animation-duration: $duration;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-iteration-count: infinite;

    @keyframes pulseBtn {
        0% {
            transform: scale(1);
            box-shadow: none;
        }
        50% {
            transform: scale($scale);
            box-shadow: 0 0 30px $color-shadow;
        }
        100% {
            transform: scale(1);
            box-shadow: none;
        }
    }
}

@mixin minAndMaxSize($width: 10px, $height: 10px) {
    min-width: $width;
    max-width: $width;
    min-height: $height;
    max-height: $height;
}

@mixin initColumnContentModal {
    @include columnCenterTopFlex();
    justify-content: space-between;
    height: 100%;
    min-height: 330px;
}

@mixin title(
    $size: var.$large-font,
    $color: white,
    $weight: bold,
    $transform: uppercase,
    $size-mobile: var.$medium-font
) {
    font-size: $size;
    color: $color;
    font-weight: $weight;
    text-transform: $transform;

    @include resolution(mobile) {
        font-size: $size-mobile;
    }
}

@mixin skeleton($width: 100%, $height: auto, $radius: 5px, $bg: var.$bg-skeleton-light) {
    overflow: hidden;
    min-height: 10px;
    min-width: 10px;
    width: $width;
    height: $height;
    background-color: $bg;
    border-radius: $radius;
}

@mixin outline($color: var.$color-secondary, $radius: 5px, $border-width: 1px, $inset: 0) {
    overflow: visible;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        inset: 0;
        border: $border-width solid $color;
        border-radius: $radius;
        pointer-events: none;
    }
}

@mixin backdrop($color: rgba(0, 0, 0, 0.7), $display: none) {
    display: $display;
    position: fixed;
    inset: 0;
    overflow: auto;
    background-color: $color;
    z-index: $zindex-backdrop;
}

@mixin priceStatus($color: var.$color-tertiary, $background: var.$gradient-low-opacity) {
    text-align: center;
    font-weight: 500;
    color: $color;
    font-style: italic;
    background: $background;
    border: none;
    font-size: 15px;
}

@mixin fontGradient($color: $gradient) {
    color: transparent;
    background-image: $color;
    -webkit-background-clip: text;
    background-clip: text;
}
