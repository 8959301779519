@import '../../shared/style/index.scss';

.avatarContainer {
    @include flex();
    flex-direction: row !important;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 10px 0;
    width: 100%;

    .avatar {
        @include columnCenterTopFlex();
        cursor: pointer;
        padding: 15px 10px;
        border-radius: 20px;
        border: 2px solid transparent;

        img {
            &:first-child {
                min-width: 125px;
                z-index: 10;
            }
            &:last-child {
                transform: translateY(-8px);
                z-index: 1;
            }
            @media (max-height: 750px) {
                &:first-child {
                    min-width: 0;
                    max-width: 110px;
                }
                &:last-child {
                    width: 80px;
                }
            }
        }
    }

    .selected {
        @include borderGradient();
    }
}
