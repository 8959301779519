@import '../../shared/style/index.scss';

.radio {
    display: none;
}

.star {
    padding: 3px;
    cursor: pointer;
    transition: color 0.2s;
    // text-shadow: -1px 0 $color-primary, 0 1px $color-primary, 1px 0 $color-quaternary, 0 -1px $color-quaternary;
}
