@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.reward {
    @include centerFlex();

    div {
        @include centerFlex();

        img {
            @include minAndMaxSize(30px, 30px);
        }

        span {
            font-weight: bold;
            margin-left: 7px;
        }
    }
}
