@import '../../../shared/style/index.scss';

.cardToggleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
    position: relative;
    width: 90%;

    @media (max-width: 1300px) {
        margin: 15px 0;
    }

    .contentCardToggle {
        width: 100%;
        border-radius: 20px;
        padding-bottom: 20px;
        overflow: hidden;
        background: $white;
        @media (max-width: 1020px) {
            min-width: 415px;
        }

        @media (max-width: 800px) {
            width: 97%;
        }

        @media (max-width: 470px) {
            min-width: 100%;
        }

        .headerCardToggle {
            display: flex;
            align-items: center;
            background: $gradient;

            .icon {
                width: 50px;
                height: 50px;
                min-width: 50px;
                min-height: 50px;
                background: $color-secondary;
                border-radius: 10px;
                padding: 5px;
            }

            h2 {
                @include title($color: $color-secondary, $size-mobile: 17px);
                margin-left: 10px;
                margin-right: 10px;
                padding: 15px;
            }
        }

        .content {
            display: grid;
            grid-template-columns: 50% 50%;
            margin-top: 10px;
            row-gap: 10px;
            justify-items: center;
            align-items: center;
            width: 100%;

            // @media (max-width: 1000px) {
            //     display: flex;
            //     justify-content: space-evenly;
            //     flex-wrap: wrap;
            // }
        }

        .arrowToggle {
            @include centerFlex();
            @include minAndMaxSize(40px, 40px);
            background: $white;
            border: 1px solid $color-quaternary;
            border-radius: 50%;
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            bottom: -22px;
            font-size: 1.2rem;

            i {
                color: $color-quaternary;
            }
        }

        .arrowToggleClose {
            background: $white;

            i {
                transform: rotate(180deg);
                color: $color-quaternary;
            }
        }
    }
}

.H100 {
    max-height: 100% !important;
}

.cardHeightSmall {
    max-height: 95px;
    overflow: hidden;
    @media (max-width: 500px) {
        max-height: 90px;
    }
    @media (max-width: 370px) {
        max-height: 220px !important;
    }
}

.bgGradient {
    background: linear-gradient(to right, #71f2b870, #31e7cf70, #00dae170, #00ccec70, #21bbee70);
    border: none !important;
    box-shadow: none !important;

    .headerCardToggle {
        h2 {
            color: $color-secondary !important;
        }
    }
}

.noData {
    display: flex !important;
}
