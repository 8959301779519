@import '../../../../shared/style/index.scss';

.carousel {
    width: calc(100% - 10px);
    margin: 30px 0;

    &Item {
        @include centerFlex();
        display: flex !important;
    }

    li {
        @include centerFlex();
        display: inline-flex;
        width: auto;
        height: auto;
        margin: 0 3px;
        cursor: initial;
    }

    .indicator {
        width: 9px;
        height: 9px;
        display: inline-block;
        margin: 0 8px;
        background: $color-dark-opacity;
        border-radius: 50%;
        cursor: pointer;
    }

    .indicatorSelected {
        width: 9px;
        height: 9px;
        display: inline-block;
        margin: 0 8px;
        border-radius: 50%;
        background: $gradient;
        cursor: pointer;
    }
}

.arrowControl {
    @include centerFlex();
    color: white;
    border-radius: 50%;
    z-index: 2;
    cursor: pointer;
    &::before {
        color: $color-secondary;
        font-size: 28px;
    }
}

.carouselSimple {
    width: 50%;
    margin: 10px 0;
    img {
        max-height: 550px;
        margin: auto;

        @media (max-width: 1020px) {
            max-height: 500px;
        }
        @media (max-width: 600px) {
            max-height: 350px;
        }
    }

    li {
        @include centerFlex();
        display: inline-flex;
        width: auto;
        height: auto;
        margin: 0;
        cursor: initial;
    }

    .indicator {
        width: 9px;
        height: 9px;
        display: inline-block;
        margin: 0 8px;
        border-radius: 50%;
        background: $color-dark-opacity;
        cursor: pointer;
    }

    .indicatorSelected {
        width: 9px;
        height: 9px;
        display: inline-block;
        margin: 0 8px;
        border-radius: 50%;
        background: $gradient;
        cursor: pointer;
    }
}
