@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.notice {
    @include centerFlex();
    margin: 10px auto 15px auto;

    .price {
        @include priceStatus();
    }

    .priceVariant {
        background: $blue-gradient-low-opacity;
        color: $color-quaternary;
    }

    i {
        margin: 0 2px 0 5px;
        font-size: 17px;
        color: $color-tertiary;
    }
}
