@import '../../../shared/style/index.scss';

.btnGradient {
    margin-top: 20px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    background: linear-gradient(to right, #71f2b8, #21bbee) border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    border: 3px solid $color-secondary;
    border-radius: 50em;
    cursor: pointer;
    transition: all 0.2s;
    color: $color-secondary;


    &:hover {
        background: linear-gradient($color-secondary, $color-secondary) padding-box,
            linear-gradient(to right, #71f2b8, #21bbee) border-box;
        border: 3px solid transparent;
        color: white;
        .circle {
            background: $gradient;
            transition: all 0.5s;
        }
    }
}

.btnSmallGradient {
    color: $color-secondary;
    width: 100%;
    font-size: 12px;
    margin: 0px;
    padding: 7px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: linear-gradient(to right, #71f2b8, #21bbee) padding-box,
        linear-gradient(to right, #71f2b8, #21bbee) border-box;
    border-radius: 50em;
    border: 1px solid transparent;
    transition: all 0.5s;
    z-index: 20 !important;
    // flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    position: relative;
    transition: all 0.2s;

    p {
        width: auto;
    }

    .circle {
        width: 15px;
        height: 15px;
    }
    &:hover {
        background: linear-gradient($color-secondary, $color-secondary) padding-box,
            linear-gradient(to right, #71f2b8, #21bbee) border-box;

        .circle {
            background: $color-primary;
            transition: all 0.5s;

            i {
                color: $color-secondary;
            }
        }
        p {
            color: $color-primary;
        }
    }
}

.btnBasic {
    color: white;
    padding: 9px 5px;
    text-align: center;
    border-radius: 25px;
    margin: 10px;
    width: 60%;
    position: relative;
    display: flex;
    z-index: 20 !important;
    // flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: linear-gradient($color-secondary, $color-secondary) padding-box,
        linear-gradient(to right, #71f2b8, #21bbee) border-box;
    border-radius: 50em;
    border: 3px solid transparent;
    transition: all 0.5s;
    cursor: pointer;

    p {
        text-transform: uppercase;
    }
    &:hover {
        background: linear-gradient(to right, #71f2b8, #21bbee) padding-box,
            linear-gradient($color-secondary, $color-secondary) border-box;
        border: 3px solid transparent;
        color: $color-secondary;
        .circle {
            background: $color-secondary;

            i {
                color: white;
            }
        }
    }
}

.btnWhite {
    color: $color-secondary;
    padding: 9px 5px;
    text-align: center;
    font-size: 18px;
    margin: 10px;
    width: 60%;
    position: relative;
    display: flex;
    z-index: 20 !important;
    // flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: linear-gradient(white, white) padding-box,
        linear-gradient($color-secondary, $color-secondary) border-box;
    border-radius: 50em;
    border: 3px solid transparent;
    transition: all 0.5s;
    cursor: pointer;

    p {
        text-transform: uppercase;
        font-weight: bold;
    }
    &:hover {
        background: linear-gradient($color-secondary, $color-secondary) padding-box,
            linear-gradient(to right, #71f2b8, #21bbee) border-box;
        border: 3px solid transparent;
        color: white;
        .circle {
            background: $gradient;
            transition: all 0.5s;
        }
    }
}

.btnSmallBasic {
    color: $color-primary;
    width: 100%;
    font-size: 12px;
    margin: 0px;
    padding: 7px;
    display: flex;
    align-items: center;
    background: linear-gradient($color-secondary, $color-secondary) padding-box,
        linear-gradient(to right, #71f2b8, #21bbee) border-box;
    border-radius: 50em;
    border: 1px solid transparent;
    transition: all 0.5s;
    z-index: 20 !important;
    // flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    position: relative;
    cursor: pointer;

    p {
        width: auto;
    }

    .circle {
        width: 15px;
        height: 15px;
    }
    &:hover {
        background: linear-gradient(to right, #71f2b8, #21bbee) padding-box,
            linear-gradient(to right, white, white) border-box;
        transition: all 0.5s;

        .circle {
            background: $color-secondary;
            transition: all 0.5s;

            i {
                color: white;
            }
        }
        p {
            color: $color-secondary;
        }
    }
}

.circle {
    background: $color-secondary;
    display: flex;
    // flex-wrap: wrap;
    justify-content: center;
    position: relative;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    color: white;
    align-items: center;
    margin-left: 15px;

    i {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 12px;
    }
}

.arrowGradient {
    background: $gradient;
    display: flex;
    // flex-wrap: wrap;
    justify-content: center;
    position: relative;
    width: 25px;
    min-width: 25px;
    height: 25px;
    min-height: 25px;
    border-radius: 50%;
    transition: all 0.5s;
    align-items: center;
    color: black;
}

.arrowBasic {
    background: $color-secondary;
    display: flex;
    // flex-wrap: wrap;
    justify-content: center;
    position: relative;
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    border-radius: 50%;
    color: white;
    align-items: center;
    margin-left: 15px;
}

button {
    &:disabled {
        background: #ccc;
        cursor: default;

        .circle {
            background: rgb(223, 223, 223);
        }

        &:hover {
            background: #ccc;
            cursor: default;
            color: white;

            .circle {
                background: rgb(223, 223, 223);
                i {
                    color: black;
                }
            }
        }
    }
}
