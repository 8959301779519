@import '../../../shared/style/index.scss';

.btnBack {
    margin: 15px 50px;
    background: $color-secondary;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 7px;
    @include centerFlex;
    text-transform: uppercase;
    cursor: pointer;

    .circle {
        background: $gradient;
        @include centerFlex;
        flex-wrap: wrap;
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: $color-secondary;
        margin-right: 5px;
        i {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scaleX(-1);
            font-size: 12px;
        }
    }

    @media screen and (max-width: 650px) {
        margin: 15px 10px;
    }
}
