@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.page {
    @include pageInit();
}

.main {
    @include mainInit();

    .container {
        @include spacingMainContent();
        .descriptionExperience {
            background: $blue-gradient;
            display: flex;
            flex-direction: row;

            @include resolution(tablet) {
                @include columnCenterTopFlex();

                &Content {
                    &Header {
                        @include columnCenterTopFlex();
                        margin: 0 $x-small;

                        h2,
                        .exclu {
                            margin-right: 0;
                        }
                    }
                }
            }

            &Content {
                margin-left: $small;
                &Header {
                    @include flex();
                    flex-wrap: wrap;
                    margin-bottom: $medium;
                    column-gap: $small;
                    row-gap: $x-small;
                    h2 {
                        @include title($color: $color-primary, $size: $x-large-font, $size-mobile: $large-font);
                    }

                    .exclu {
                        color: $color-quaternary;
                        @include outline($color: $color-quaternary);
                    }

                    .visionsStars {
                        background: $gradient;
                        border-radius: $small;
                        padding: 2px $small;

                        img {
                            @include minAndMaxSize(25px, 25px);
                        }
                    }
                }
                p {
                    color: $white;
                }

                &SubDescription {
                    font-style: italic;
                    margin-top: $medium;
                    font-weight: 300;
                }
            }
        }

        .iframeContainer {
            padding: $small;
            padding-bottom: 50px;
            width: auto;
            min-width: 300px;
            margin: 0;

            .results {
                background: $gradient-low-opacity;
                margin-bottom: $medium;

                h3 {
                    font-weight: bold;
                    font-size: $medium-font;
                    color: $color-secondary;
                }

                img {
                    margin: $medium 0 $x-small 0;
                    max-width: 500px;
                }
            }

            iframe {
                width: 100%;
                min-height: 800px;
                @include borderGradient();
                border-radius: $small;
            }
        }
    }

    .col {
        @include spacingMainContent();

        .titleCard {
            padding: 0;
            overflow: hidden;
            background: $white;

            &Header {
                background: $gradient;
                h3 {
                    color: $color-secondary;
                }

                i {
                    color: transparent;
                    background-image: $gradient;
                    -webkit-background-clip: text;
                    background-clip: text;
                }
            }

            &Content {
                padding: $small;
                font-size: $normal-font;

                span {
                    font-weight: bold;
                }

                a {
                    color: $color-quaternary;
                }

                ul {
                    margin: $large 0;
                }

                button {
                    margin: $small auto;
                    text-transform: initial;
                    color: $color-quaternary;
                    font-weight: 500;
                    font-size: $normal-font;

                    &:hover {
                        color: $color-secondary;
                    }
                }

                &Advice {
                    display: grid;
                    grid-template-columns: 50px auto;
                    gap: $large;
                    margin: $medium;

                    img {
                        @include minAndMaxSize(50px, 50px);
                        align-self: center;
                    }

                    @include resolution(mobile) {
                        @include columnCenterTopFlex();
                        text-align: center;
                        gap: $small;
                    }
                }
            }
        }

        .manual {
            width: auto;
            min-height: 300px;
            background: $white;
            margin: 0;
        }
    }
}
