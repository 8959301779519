@import '../../../shared/style/index.scss';

.card {
    width: 90%;
    min-width: 90%;
    height: max-content;
    min-height: 200px;
    background: $border-gradient;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin: 15px 0;
    // padding: 15px;
    padding-bottom: 50px;
    border-radius: 20px;
    position: relative;
    border-color: transparent !important;

    .bg {
        background: $gradient;
        width: 100%;
        height: 50px;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 0 0 17px 17px;
    }

    .content {
        height: 100%;
        overflow: hidden;
    }
}

.blue {
    border-color: $color-secondary !important;
    .bg {
        background: $blue-gradient;
    }
}

.soon {
    &::after {
        background: rgba(0, 0, 0, 0.747);
        content: 'Prochainement';
        color: white;
        font-weight: bold;
        font-size: 22px;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 90;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
    }
}

.row {
    display: flex;
    flex-direction: row;
}

.border {
    border: 1px solid;
}

.scale {
    transition: transform 0.2s;
    transform: scale(1.05);
}

.hoverBlue {
    &:hover {
        @include outline($radius:20px);
        .bg {
            background: $color-secondary;
        }
    }
}
