@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.button {
    display: inline-flex;
    align-items: center;
    @include title($size: $normal-font, $transform: initial, $weight: 500);
    background: $color-secondary;
    cursor: pointer;
    border-radius: $large;
    gap: $small;
    padding: 6px $medium;
    transition: transform 0.2s;
    border: 1px solid;
    border-color: $color-secondary;
    width: max-content;
    max-width: 400px;
    box-shadow: $small-shadow;
    color: $color-quaternary;

    span {
        align-self: center;

        @include resolution(mobile) {
            font-size: $small-font;
        }
    }

    .circle {
        background: $gradient;
        border-radius: 50%;
        @include minAndMaxSize(22px, 22px);
        @include centerFlex();
        padding: $small;
    }

    &:hover {
        transform: scale(1.02);
        background: $gradient;
        span {
            color: $color-secondary;
        }

        .circle {
            background: $blue-gradient;
        }

        .loader {
            span {
                background: $color-secondary;
            }
        }
    }

    &:disabled {
        background: $bg-blue-opacity;
        pointer-events: none;
    }

    @include resolution(mobile) {
        width: auto;
    }
}

.gradient {
    border-color: transparent;
    background: $gradient padding-box, $gradient border-box;
    color: $color-secondary;

    .circle {
        background: $blue-gradient;
    }

    .loader {
        span {
            background: $color-secondary;
        }
    }

    &:hover {
        background: $blue-gradient padding-box, $gradient border-box;
        span {
            color: $color-quaternary;
        }

        .circle {
            background: $gradient;
        }

        .loader {
            span {
                background: $gradient;
            }
        }
    }
}

.blueGradient {
    background: $blue-gradient padding-box, $blue-gradient border-box;
    color: $color-quaternary;

    &:hover {
        background: $gradient padding-box, $blue-gradient border-box;
    }
}

.white {
    @include borderGradient($border-color-one: #fff, $border-color-two: #fff, $border-size: 1px);
    color: $color-secondary;

    .loader {
        span {
            background: $color-secondary;
        }
    }

    &:hover {
        @include borderGradient(
            $border-color-one: $color-quaternary,
            $border-color-two: $color-primary,
            $border-size: 1px
        );
    }
}

.bordered {
    background: transparent;
    color: $color-secondary;

    .circle {
        background: $blue-gradient;
    }

    .loader {
        span {
            background: $color-secondary;
        }
    }

    &:hover {
        background: transparent;
    }
}

.borderedGreen {
    background: transparent;
    color: $color-quaternary;
    border-color: $color-quaternary;

    .circle {
        background: $color-quaternary;
    }

    &:hover {
        background: transparent;
        span {
            color: $color-quaternary;
        }

        .circle {
            background: $color-quaternary;
        }

        .loader {
            span {
                background: $color-quaternary;
            }
        }
    }
}

.main {
    text-transform: uppercase;
    font-weight: bold;
}

.largeMain {
    text-transform: uppercase;
    font-weight: bold;
    font-size: $medium-font;

    @include resolution(mobile) {
        font-size: $normal-font;
    }
}

.small {
    font-size: $small-font;
}

.smallMain {
    font-size: $small-font;
    text-transform: uppercase;
    font-weight: bold;
}

.pulse {
    animation-name: pulseBtn;
    animation-duration: 1.5s;
    animation-fill-mode: both;
    // animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-iteration-count: infinite;
    box-shadow: 0 0 0 0 rgba($color-tertiary, 0.5);
    @keyframes pulseBtn {
        0% {
            transform: scale(0.9);
        }
        70% {
            transform: scale(1);
            box-shadow: 0 0 0 30px rgba($color-tertiary, 0);
        }
        100% {
            transform: scale(0.9);
            box-shadow: 0 0 0 0 rgba($color-tertiary, 0);
        }
    }

    &:hover {
        animation: none;
    }
}

.square {
    border-radius: $x-small;
    background: $blue-gradient;

    i {
        color: $color-secondary;
    }

    &:hover {
        i {
            color: $color-quaternary;
        }
    }
}

.squareWhite {
    border-radius: $x-small;
    background: $white;
    color: $color-secondary;

    &:hover {
        i {
            color: $color-quaternary;
        }
    }
}
