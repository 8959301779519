@import '../../../../shared/style/variable';
@import '../../../../shared/style/mixins';

.page {
    background: url('../../../../img/fond.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    @include columnCenterFlex;

    section {
        max-width: 1200px;
        min-width: 200px;
    }
}

.card {
    @include centerFlex;
    background: $gradient;
    border-radius: 20px;
    padding: 20px;
    width: 90%;
    max-width: 1200px;
    margin-bottom: 30px;

    .icon {
        margin-right: 30px;
        @include centerFlex;

        img {
            width: 80px;
            min-width: 80px;
            height: 80px;
        }
    }

    .information {
        width: 80%;
        h2 {
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        P {
            font-size: 18px;
            span {
                font-weight: bold;
            }
        }
    }

    @media (max-width: 480px) {
        flex-direction: column;

        .icon {
            margin: 0px 0 20px 0;
        }

        .information {
            width: 90%;

            h2 {
                text-align: center;
            }
        }
    }
}

.parameter {
    padding: 20px;
    padding-bottom: 40px;
    @include columnCenterTopFlex;

    form {
        @include columnCenterTopFlex;

        .inputGroup {
            @include centerFlex;
            align-items: stretch;
            margin-bottom: 30px;

            .input {
                @include columnCenterTopFlex;
                justify-content: space-between;
                align-items: flex-start;
                width: 50%;
                margin: 10px;

                label {
                    font-weight: bold;
                    font-size: 17px;

                    span {
                        font-weight: 400;
                    }
                }

                input {
                    background: $gradient-opacity;
                    border: none;
                    width: 100%;
                    max-width: 300px;
                    margin-top: 10px;
                    border-radius: 30px;
                    font-size: 18px;
                    padding: 6px 10px;
                }
            }

            @media (max-width: 600px) {
                flex-direction: column;
                align-items: center;

                .input {
                    width: 90%;
                }
            }
        }
    }

    .btn {
        margin-bottom: 15px;

        button {
            box-shadow: $shadow;
        }
    }

    .skipStep {
        cursor: pointer;
        font-weight: 500;
        margin-top: 5px;
        transition: transform 0.1s;

        &:hover {
            transform: scale(1.03);
        }
    }
}
