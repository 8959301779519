@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.page {
    @include pageInit();

    .container {
        @include gridMainPage();

        .content {
            @include columnCenterTopFlex();
            width: 100%;

            .card {
                @include columnCenterTopFlex();
                justify-content: space-between;
                height: max-content;
                min-height: 350px;
                width: 97%;
                min-width: 45%;
                border-radius: 20px;
                overflow: hidden;
                position: relative;
                padding: 20px 0;

                .bgGradient {
                    position: absolute;
                    inset: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(to top, $color-primary, $color-quaternary, transparent 65%);
                    z-index: 10;
                }

                .bgImage {
                    position: absolute;
                    inset: 0;
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-position: center;
                    z-index: 1;
                    opacity: 0.5;
                }

                .banner {
                    z-index: 10;
                    align-self: flex-start;
                    background: $blue-gradient;
                    color: white;
                    padding: 5px 20px;
                    border-radius: 0 10px 10px 0;
                    text-transform: uppercase;
                    font-weight: bold;
                }

                .info {
                    z-index: 10;
                    text-align: center;
                    @include columnCenterTopFlex();

                    .desc {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        font-weight: bold;
                        padding: 0 10px;
                    }

                    button {
                        text-transform: lowercase;
                        margin-top: 15px;
                        p {
                            margin-bottom: 3px;
                        }
                    }
                }

                @media (max-width: 700px) {
                    min-width: 75%;
                }
                @media (max-width: 500px) {
                    min-width: 85%;
                }
                @media (max-width: 420px) {
                    min-width: 95%;
                }
            }

            .category {
                @include flex();
                justify-content: center;
                flex-wrap: wrap;
                .square {
                    @include columnCenterTopFlex();
                    cursor: pointer;
                    background: $gradient;
                    border-radius: 20px;
                    margin: 10px;
                    text-align: center;
                    @include minAndMaxSize(130px, 130px);

                    img {
                        @include minAndMaxSize(60px, 60px);
                        margin: 10px;
                        filter: brightness(0.2);
                    }

                    @media (max-width: 700px) {
                        @include minAndMaxSize(110px, 110px);
                        justify-content: space-around;
                        img {
                            @include minAndMaxSize(40px, 40px);
                        }
                    }
                }
                .active {
                    background: $blue-gradient;
                    color: $white;

                    img {
                        filter: none;
                    }
                }
            }

            .selectedEvents {
                padding: 0 10px;
                section {
                    overflow: hidden;
                    min-width: 97%;
                    box-shadow: $soft-shadow-content;
                    height: auto;
                }

                .cardContainer {
                    @include columnCenterTopFlex();
                    justify-content: space-between;
                    height: 100%;

                    .cardContent {
                        width: 100%;

                        .header {
                            @include columnCenterTopFlex();
                            width: 100%;
                            img {
                                min-height: 200px;
                                max-height: 200px;
                                width: 100%;
                                object-fit: cover;
                            }

                            h3 {
                                margin: 10px 0;
                                text-transform: uppercase;
                                font-weight: bold;
                                color: $color-secondary;
                            }
                        }

                        .infoEvents {
                            @include columnCenterTopFlex();
                            width: 100%;
                            .tags {
                                @include centerFlex();
                                flex-wrap: wrap;
                                height: 64px;

                                .tag {
                                    background: $bg-disabled;
                                    text-transform: uppercase;
                                    font-weight: 500;
                                    padding: 3px 10px;
                                    border-radius: 5px;
                                    margin: 5px 10px;
                                }

                                .date {
                                    background: $gradient-low-opacity;
                                    border-radius: 5px;
                                    padding: 3px 10px;
                                    font-weight: 500;
                                }
                            }

                            .desc {
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                overflow: hidden;
                                font-weight: bold;
                                text-align: center;
                                margin: 10px 0;
                                padding: 0 5px;
                                height: 42px;
                            }
                        }
                    }

                    button {
                        margin: 10px 0 20px 0;
                    }
                }
            }

            .showAllEvents {
                @include centerFlex();
                flex-wrap: wrap;
                gap: 20px;
                section {
                    overflow: hidden;
                    min-width: 300px;
                    width: 45%;
                    box-shadow: $soft-shadow-content;
                    height: auto;

                    @media (max-width: 700px) {
                        min-width: 100px;
                        width: 90%;
                    }
                }
            }

            .btn {
                @include centerFlex();
                width: 100%;
                margin: 10px 0;
            }
        }

        .col {
            width: 100%;
            @include columnCenterTopFlex();

            .cardTop {
                @include flex();
                background: $gradient-low-opacity;
                border-radius: 20px;
                margin: 10px 0;
                width: 100%;

                .cardLogo {
                    background: $white;
                    align-self: flex-start;
                    @include minAndMaxSize(90px, 90px);
                    @include outline($border-width: 2px, $radius: 10px);
                    border-radius: 10px;
                    margin: 10px;
                    width: 40%;

                    img {
                        @include minAndMaxSize(90px, 90px);
                        object-fit: contain;
                    }
                }

                &Content {
                    width: auto;
                    margin: 15px 5px;

                    .tag {
                        font-weight: 500;
                        text-transform: uppercase;
                        background: $bg-grey-opacity;
                        width: max-content;
                        padding: 5px 10px;
                        border-radius: 5px;
                    }

                    .date {
                        @include centerFlex();
                        justify-content: flex-start;
                        margin-top: 10px;
                        font-weight: 500;
                        img {
                            @include minAndMaxSize(20px, 20px);
                            filter: brightness(0.3);
                            margin-right: 10px;
                        }
                    }

                    .desc {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        overflow: hidden;
                        font-weight: bold;
                        margin: 10px 0;
                    }

                    button {
                        text-transform: initial;
                        color: $color-quaternary;

                        i {
                            color: $color-secondary;
                        }

                        div {
                            width: 26px;
                        }

                        &:hover {
                            color: $color-secondary;
                            i {
                                color: $white;
                            }
                        }
                    }
                }

                @media (max-width: 450px) {
                    @include columnCenterTopFlex();

                    .cardLogo {
                        width: 100%;
                        margin: 10px auto;
                    }

                    &Content {
                        @include columnCenterTopFlex();
                        text-align: center;
                    }
                }
            }
        }

        @media (max-width: 1020px) {
            @include columnCenterTopFlex();
            justify-content: flex-start;
        }
    }
}
