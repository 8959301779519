@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.page {
    background: $blue-gradien-top;
    width: 100%;
    min-height: 100vh;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    overflow: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    .container {
        @include columnCenterTopFlex();
        min-height: 100vh;
        padding-top: 85px;
        padding-bottom: 130px;
        justify-content: space-between;

        .bottom {
            width: 100%;
            @include columnCenterFlex();

            .steps {
                @include centerFlex();
                width: 100%;
                height: max-content;
                margin: 10px 0;
                .dot {
                    @include minAndMaxSize(15px, 15px);
                    background: $bg-disabled;
                    border-radius: 50%;
                    margin: 5px 10px;
                }

                .active {
                    background: $gradient;
                }
            }

            .btn {
                @include centerFlex();
                justify-content: space-between;
                width: 80%;
                flex-wrap: wrap;
                margin: 10px 0;

                button {
                    margin: 10px 0;
                    font-size: 16px;

                    &:first-child {
                        background: none;
                        color: $color-quaternary;
                        box-shadow: none;
                    }

                    &:last-child:hover {
                        border: 1px solid $color-quaternary;
                    }
                }
            }
        }
    }
}
