@import '../../../shared/style/variable';

.card {
    background: $gradient;
    width: auto;
    min-height: 100px;
    border-radius: 20px;
    color: $color-secondary;
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-shadow: $small-shadow;
    overflow: hidden;
    position: relative;

    .bar {
        background: $blue-gradient;
        height: 45px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    .barGradient {
        background: $gradient;
    }
}
