@import '../../../shared/style/index.scss';

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: none;
    overflow: auto;
    background-color: #000000;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;

    .modal {
        position: fixed;
        width: 95%;
        max-width: 492px;
        min-height: 470px;
        max-height: 85%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 30px;
        border: solid 2px $color-secondary;
        transition: all 0.5s;
        background: $gradient-top;
        z-index: 9999;

        .modalHeader {
            height: 60px;
            background: $blue-gradient;
            width: 100%;
            border-radius: 25px 25px 0 0;
            position: relative;

            &::before {
                content: '';
                display: block;
                width: 100%;
                position: absolute;
                height: 4px;
                background: white;
                bottom: 0;
            }

            .modalLogo {
                @include centerFlex;
                position: relative;
                width: 70px;
                height: 70px;
                background: white;
                border-radius: 50%;
                bottom: -20px;
                margin-left: auto;
                margin-right: auto;
                border: 5px solid $color-secondary;
                padding: 7px;
                &::before {
                    content: '';
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    border: 2px solid white;
                    position: absolute;
                }

                .modalImg {
                    width: 60px;
                    height: 60px;
                    object-fit: contain;
                    border: none;
                    max-width: 100%;
                    border-radius: 50%;
                    z-index: 10;
                }
            }
            .closeModal {
                @include centerFlex;
                position: absolute;
                top: 10px;
                right: 20px;
                background: $gradient;
                height: 35px;
                width: 35px;
                border-radius: 50%;
                font-size: 27px;
                font-weight: 700;
                cursor: pointer;
                text-align: center;

                &:hover {
                    transform: scale(1.05);
                }

                span {
                    transform: translateY(-3px);
                }
            }
        }
        .modalContent {
            margin-top: 30px;
            padding: 15px;
            min-height: 340px;
            max-height: 520px;
            overflow: hidden;

            overflow-y: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }

            @media (max-height: 700px) {
                max-height: 380px;
            }
            @media (max-height: 481px) {
                max-height: 370px;
            }
        }
    }
}

.openModal {
    display: block;
}
