@import '../../shared/style/index';

.gaugeContainer {
    @include columnCenterTopFlex;
    width: 100%;
    transition: all 0.5s;
    margin: 15px 0;

    .gauge {
        background: $color-secondary;
        width: 100%;
        max-width: 700px;
        height: 20px;
        border-radius: 20px;
        position: relative;
        margin: 0 10px;
        transition: all 0.2s;
        overflow: hidden;

        @media (min-width: 1600px) {
            max-width: 700px;
        }

        .meter {
            background: $gradient;
            width: 0%;
            height: 20px;
            border-radius: 20px;
            position: absolute;
            left: 0px;
            transition: width 0.15s;
        }
    }
    .stepGaugeMeter {
        color: $color-secondary;
        font-weight: bold;
        margin-top: 10px;
        text-transform: uppercase;
    }
}

.basic {
    .gauge {
        background: $white;
        border: 2px solid $color-secondary;
    }

    .meter {
        background: $blue-gradient !important;
    }
}
