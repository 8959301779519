.page {
    background: #e7f9fd;
    min-height: 100vh;
}

.main {
    display: grid;
    grid-template-columns: 65% 35%;
    padding-bottom: 80px;
    position: relative;

    .col {
        margin-top: 15px;

        .exp {
            display: flex;
            justify-content: center;
        }
    }

    @media (max-width: 1020px) {
        display: flex;
        flex-direction: column;

        .content {
            width: 100% !important;
        }

        .col {
            width: 100% !important;

            section {
                flex-direction: row;
                width: 90%;
                height: auto;
                padding: 20px;
                margin-top: 30px;
            }
        }
    }

    @media (max-width: 630px) {
        .col {
            section {
                flex-direction: column;
            }
        }
    }

    .content {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .col {
        display: flex;
        flex-direction: column;
        align-items: center;

        .linkCard {
            width: 95%;
        }
    }

    @media (max-width: 470px) {
        .exp {
            section {
                width: 100%;
            }
        }
    }
}
