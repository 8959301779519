@use '../../../shared/style/variable' as *;
@use '../../../shared/style/mixins' as *;

.header {
    background: $blue-gradient;
    width: 100%;
    color: $white;
    @include flex();
    padding: $x-small $small;

    img {
        @include minAndMaxSize(40px, 40px);
        margin-right: $medium;
    }

    i {
        font-size: $x-large-font;
    }

    .titles {
        @include flex();
        flex-wrap: wrap;
        h3 {
            @include title();
            margin-right: $medium;
        }

        span {
            @include title($size: $normal-font, $size-mobile: $small-font, $weight: 500, $transform: initial);
            margin-top: 3px;
        }
    }
}

.light {
    background: $gradient;

    .titles {
        h3,
        span {
            @include title($color: $color-secondary);
        }

        span {
            font-size: $normal-font;
        }
    }
}

.lightOpacity {
    background: $gradient-low-opacity;
    .titles {
        h3,
        span {
            @include title($color: $color-secondary);
        }

        span {
            font-size: $normal-font;
        }
    }
}

.transparent {
    background: none;

    .titles {
        h3,
        span {
            @include title($color: $color-secondary);
        }

        span {
            font-size: $normal-font;
        }
    }
}

.bgImage {
    .imageContainer {
        @include centerFlex();
        background: $blue-gradient;
        @include minAndMaxSize(45px, 45px);
        border-radius: $small;
        margin-right: $small;
    }
}
