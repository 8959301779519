@import '../../../shared/style/index.scss';

.container {
    @include columnCenterTopFlex;
    padding: 0 30px;
    position: relative;

    .header {
        @include columnCenterTopFlex;
        margin: 15px 0;
        .logo {
            width: 100px;
            height: 100px;
            border: 2px solid $color-secondary;
            border-radius: 20px;
            overflow: hidden;

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
        h2 {
            text-transform: uppercase;
            color: $color-primary;
            font-weight: bold;
            font-size: 20px;
            margin-top: 10px;
        }
    }

    .content {
        @include columnCenterTopFlex;
        width: 100%;
        .description {
            text-align: center;
            font-weight: 500;
            margin-bottom: 10px;
        }

        .socialMedia {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            background: $gradient-opacity;
            width: 95%;
            min-width: 95%;
            border-radius: 5px;
            padding: 10px 30px;
            margin: 15px 0;

            @media (max-width: 374px) {
                min-width: 100%;
                width: 100%;
            }

            .media {
                @include centerFlex;
                background: $color-secondary;
                border-radius: 5px;
                width: 40px;
                min-width: 40px;
                height: 40px;
                min-height: 40px;
                cursor: pointer;

                i {
                    color: transparent;
                    background-clip: text;
                    -webkit-background-clip: text;
                    background-image: $gradient;
                    font-size: 27px;
                }
            }

            @media (max-width: 470px) {
                flex-wrap: wrap;
                .media {
                    margin: 5px;
                }
            }
        }

        .btn {
            margin: 10px 0 20px 0;

            .button {
                i {
                    font-size: $normal-font;
                }
                &:hover {
                    i {
                        color: $color-quaternary;
                    }
                }
            }
        }
    }
}

.none {
    display: none;
}
