@import '../../../../shared/style/index.scss';

.tool {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% / 3);
    background: transparent;
    padding: 30px 10px 20px 10px;
    flex-wrap: wrap;

    @media (max-width: 620px) {
        width: 100%;
    }

    .picto {
        background-size: contain;
        width: 100%;
        height: 60px;
        background-repeat: no-repeat;
        background-position: center;
    }

    p {
        width: 80%;
        font-weight: 700;
        text-align: center;
        margin: 1rem 0;
    }
}