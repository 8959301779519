.container {
    .globalRank {
        background: linear-gradient(to right, #71f2b870, #31e7cf70, #00dae170, #00ccec70, #21bbee70);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        border-radius: 10px;
        margin-top: 20px;

        .contentRank {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin: 5px 0;

            p {
                width: 40%;
                font-weight: 600;
            }

            .stars {
                width: 40%;
                display: flex;
                align-items: center;
                img {
                    width: 20px;
                    height: 20px;
                }

                .star {
                    padding: 3px;
                }
            }

            .radio {
                display: none;
            }
        }
    }
    .btn {
        display: flex;
        justify-content: center;

        button {
            color: white;
            font-weight: bold;
            text-transform: uppercase;
            width: max-content;
            padding: 7px 15px;
        }
    }
}
