@import '../../../shared/style/mixins';
@import '../../../shared/style/variable';

.page {
    // @include pageInit();

    @include resolution(mobile) {
        padding: 20px;
        padding-bottom: 90px;
    }
    @include resolution(x-mobile) {
        padding: 20px 5px;
        padding-bottom: 90px;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .head {
        padding: 30px;
        h1 {
            text-align: center;
            font-size: 30px;
        }
    }

    .body {
        padding: 30px;
    }
}