@import '../../../../shared/style/index.scss';

.content {
    font-size: 18px;
    text-align: center;
    @include columnCenterTopFlex;
    justify-content: space-between;
    min-height: 300px;

    .information {
        .header {
            @include columnCenterTopFlex;
            h3 {
                font-size: 25px;
                text-transform: uppercase;
                color: $color-tertiary;
                font-weight: 600;
                width: 100%;
            }
            i {
                position: relative;
                margin-top: 10px;
                font-size: 22px;
                cursor: pointer;
                .tooltip {
                    display: none;
                    line-height: 1.3;
                    position: absolute;
                    width: 100px;
                    height: max-content;
                    background: $bg-grey;
                    margin: 0;
                    font-size: 17px;
                    padding: 10px;
                    transform-origin: center;
                    text-transform: none;
                    margin-top: 12px;
                    border-radius: 5px;
                    text-align: left;
                    animation: pulseTop 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
                    z-index: 10;
                }
                .arrowUp {
                    display: none;
                    position: absolute;
                    bottom: -13px;
                    left: 1.5px;
                    margin: 0 auto;
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;

                    border-bottom: 10px solid $bg-grey;
                }
            }

            @media (max-width: 370px) {
                flex-direction: column;
                h3 {
                    width: 100%;
                }
                i {
                    .tooltip {
                        left: -140px;
                    }
                }
            }
        }

        .exchange {
            @include centerFlex;
            justify-content: space-evenly;
            width: 100%;
            margin: 30px 0 10px 0;

            div {
                @include columnCenterFlex;
                i {
                    color: $color-tertiary;
                    font-size: 40px;
                    margin: 0 5px;
                    margin-bottom: 20px;
                }
                img {
                    width: 100px;
                    height: 100px;
                    border-radius: 10px;
                }
                span {
                    margin-top: 5px;
                    font-weight: bold;
                    color: $color-secondary;
                }
            }

            @media (max-width: 430px) {
                div {
                    i {
                        font-size: 25px;
                    }
                    img {
                        width: 80px;
                        height: 80px;
                    }
                }
            }
        }

        .description {
            cursor: pointer;
            font-weight: 600;
            margin-bottom: 5px;
        }

        .hideDescription {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }

        .readMore {
            text-align: right;
            width: 95%;
            cursor: pointer;
            margin-top: 0;
            margin-right: 10px;
        }

        p {
            margin: 20px 0;
            color: $color-secondary;
            span {
                font-weight: bold;
            }
        }

        table {
            width: 100%;
        }

        .email {
            width: 100%;
            margin: 10px 0;
            input {
                width: 90%;
                border-radius: 30px;
                border: none;
                border: 2px solid $color-secondary;
                font-size: 17px;
                padding: 4px;
                padding-left: 10px;
                &:focus {
                    border: 3px solid $color-tertiary;
                }
            }
        }

        .checkAccount {
            @include centerFlex;
            position: relative;
            cursor: pointer;
            margin: 20px 0 10px 0;

            input {
                position: absolute;
                cursor: pointer;
                opacity: 0;
                width: 0;
                height: 0;

                &:checked ~ .checkbox {
                    background: $color-tertiary;

                    &::after {
                        display: block;
                    }
                }
            }

            .checkbox {
                @include centerFlex;
                background: $bg-grey;
                width: 20px;
                height: 20px;
                min-width: 20px;
                min-height: 20px;
                border-radius: 5px;
                margin-right: 10px;
                &::after {
                    content: '';
                    position: absolute;
                    display: none;
                    width: 5px;
                    height: 10px;
                    border: solid white;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
        }

        .recap {
            display: flex;
            align-items: center;
            margin: 20px 0;
            p {
                width: 50%;
                font-weight: 500;
            }
            img {
                max-height: 180px;
                width: 50%;
            }

            @media (max-width: 600px) {
                flex-direction: column;
                p,
                img {
                    width: 100%;
                }

                img {
                    max-height: 150px;
                }
            }
        }
    }

    .btn {
        margin: 20px 0px 10px 0;
    }

    @media (max-height: 700px) {
        h3 {
            font-size: 18px !important;
        }

        .header {
            i {
                display: none;
            }
        }

        .information {
            .exchange {
                margin: 15px 0 10px 0;

                div {
                    i {
                        font-size: 20px;
                    }

                    img {
                        width: 60px;
                        height: 60px;
                    }
                }
            }

            .readMore {
                margin-bottom: 0;
            }
        }
    }
}

.error {
    @include centerFlex;
    font-size: 18px;
    font-weight: 500;
    color: $color-secondary;

    i {
        margin-left: 5px;
        color: orange;
    }
}

@keyframes pulseTop {
    0% {
        transform: scale(0.5);
        transform-origin: 50% 0%;
        @media screen and (max-width: 710px) {
            transform-origin: 100% 0%;
        }
    }
    100% {
        transform: scale(1);
        transform-origin: 50% 0%;
        @media screen and (max-width: 710px) {
            transform-origin: 100% 0%;
        }
    }
}

@keyframes pulseTopMobile {
    0% {
        transform: scale(0.5);
        transform-origin: 100% 0%;
    }
    100% {
        transform: scale(1);
        transform-origin: 100% 0%;
    }
}
