.content {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-items: center;

    @media (max-width: 1300px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
