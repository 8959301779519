html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

button,
input,
select,
textarea {
  margin: 0;
}

html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

img,
video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

td:not([align]),
th:not([align]) {
  text-align: left;
}

html {
  --v-blue: #21bbee;
  --v-dark-blue: #18243f;
  --bg-vt: linear-gradient(
        to right,
        #71f2b8,
        #31e7ce,
        #00dae1,
        #00ccec,
        #21bbee
    );
  --bg-vt-top: linear-gradient(
        to top,
        #71f2b8,
        #31e7ce,
        #00dae1,
        #00ccec,
        #21bbee
    );
}

.vt-btn {
  color: white;
  padding: 9px 5px;
  text-align: center;
  border-radius: 25px;
  margin: 10px;
  width: 60%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 20 !important;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: -webkit-gradient(linear, left top, left bottom, from(var(--v-dark-blue)), to(var(--v-dark-blue))) padding-box, -webkit-gradient(linear, left top, right top, from(#71f2b8), to(#21bbee)) border-box;
  background: linear-gradient(var(--v-dark-blue), var(--v-dark-blue)) padding-box, linear-gradient(to right, #71f2b8, #21bbee) border-box;
  border-radius: 50em;
  border: 1px solid transparent;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.vt-btn p {
  padding: 0px;
  padding-right: 20px;
  width: 80%;
}

.vt-btn .circle {
  background: var(--bg-vt);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.vt-btn .circle i {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 12px;
}

.vt-btn:hover {
  background: -webkit-gradient(linear, left top, right top, from(#71f2b8), to(#21bbee)) padding-box, -webkit-gradient(linear, left top, right top, from(white), to(white)) border-box;
  background: linear-gradient(to right, #71f2b8, #21bbee) padding-box, linear-gradient(to right, white, white) border-box;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.vt-btn:hover .circle {
  background: var(--v-dark-blue);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.vt-btn:hover p {
  color: var(--v-dark-blue);
}

.title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding-top: 50px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.title h2 {
  font-size: 16px;
  color: black;
  font-weight: 700;
  text-transform: uppercase;
  padding-left: 10px;
}

.title .logo {
  width: 50px;
  height: 50px;
  border-radius: 13px;
  border: solid 1px black;
  margin: 5px;
  margin-left: 20px;
  background: var(--bg-vt);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.title .logo img {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

body {
  font-family: 'KoHo', sans-serif;
}
