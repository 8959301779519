.container {
    background: var(--v-dark-blue);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 90%;
    height: max-content;
    min-height: 300px;
    padding: 20px;

    @media (max-width: 1020px) {
        width: 45%;
    }

    @media (max-width: 800px) {
        width: 90%;
        margin-top: 20px;
    }
}