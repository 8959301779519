@import '../../../../shared/style/index.scss';

.contentModal {
    @include initColumnContentModal();
    margin-top: 10px;

    .informationModal {
        @include columnCenterTopFlex();
        width: 100%;
        text-align: center;

        .congrat {
            @include title($color: $color-tertiary);
            margin-bottom: 5px;
        }
        .recap {
            @include columnCenterFlex();
            margin: 10px 0;
            font-size: 17px;
            font-weight: 500;

            p {
                margin: 5px 0;
            }

            .important,
            > span {
                font-weight: 700;
                font-size: 18px;
            }

            .promoCode {
                @include centerFlex();
                justify-content: space-evenly;
                flex-wrap: wrap;
                margin: 10px 0;
                width: 100%;
                word-break: break-all;

                p {
                    margin: 0;
                }

                #codeInput {
                    border: none;
                    background: transparent;
                    color: $color-secondary;
                    font-weight: bold;
                    text-align: center;
                    font-size: 20px;
                    pointer-events: none;
                    width: 100%;
                    margin: 5px 0;
                }

                button {
                    margin: 5px 0;

                    &:active {
                        transition: all 0.1s;
                        transform: scale(0.9);
                    }
                }
            }
        }
    }
}
