@import '../../../../shared/style/index.scss';

.card {
    background: $gradient;
    width: auto;
    height: auto;
    border-radius: 20px;
    margin: 15px 10px;
    @include columnCenterTopFlex;
    justify-content: space-between;
    text-align: center;
    padding: 15px;

    .img {
        width: 50px;
        height: 50px;
    }

    .textContent {
        @include columnCenterTopFlex();
        width: 100%;
        h3 {
            color: $color-secondary;
            font-size: 19px;
            font-weight: 700;
            margin: 10px 0;
        }

        p {
            color: $color-secondary;
            font-size: 17px;
            font-weight: 500;
            width: 90%;
        }
    }

    .score {
        font-weight: 500;

        p {
            font-size: 18px;
        }

        .round {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #a0ebf0;
            border-radius: 10px;
            margin: 5px 0;
            padding: 5px 15px;

            span {
                margin-left: 5px;
                font-size: 19px;
                font-weight: bold;
            }

            img {
                @include minAndMaxSize(25px, 25px);
            }
        }

        .count {
            font-size: 35px;
            font-weight: bold;
        }
    }

    @media (max-width: 660px) {
        width: 95%;
    }
}

.cardCatalog {
    background: $gradient-mid-opacity;

    .score {
        p {
            margin-top: 10px;
        }
    }
}