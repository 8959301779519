@import '../../shared/style/variable';
@import '../../shared/style/mixins';

.container {
    display: flex;
    flex-direction: column;
    padding: 15px;

    .header {
        display: flex;

        .logo {
            @include centerFlex();

            .img {
                @include borderGradient();
                @include centerFlex();
                border-radius: 20px;
                width: 120px;
                height: 120px;

                img {
                    padding: 6px;
                }
            }
        }

        .headline {
            display: flex;
            flex-direction: column;
            margin-left: 20px;

            .title {
                @include title($size: 20px, $color: $color-primary);
                margin: 10px 10px 20px 10px;
            }

            .headlineInfo {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                @media (max-width: 465px) {
                    flex-direction: column;
                    justify-content: center;
                }
                .mainTag {
                    color: $color-secondary;
                    background: $gradient-opacity;
                    text-align: center;
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 13px;
                    padding: 10px;
                    border-radius: 10px;
                    margin-left: 10px;
                }

                .visionsStars {
                    display: flex;
                    align-items: center;
                    background: $gradient-opacity;
                    padding: 10px 15px;
                    border-radius: 10px;
                    font-size: 15px;
                    max-height: 37px;
                    margin: 10px;

                    img {
                        @include minAndMaxSize(25px, 25px);
                    }

                    span {
                        margin-left: 5px;
                    }
                }
            }
        }

        @media (max-width: 470px) {
            flex-direction: column;
            align-items: center;

            .headline {
                margin: 0;
                .title {
                    margin: 10px auto;
                }
            }
        }
    }

    .description {
        font-weight: 600;
        color: $color-secondary;
        margin: 20px 0;
        font-size: 17px;
    }

    .tags {
        background: $gradient-low-opacity;
        display: flex;
        align-items: center;
        padding: 20px;
        border-radius: 10px;

        .showTags {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin-left: 50px;

            p {
                color: $color-secondary;
                margin: 0 10px;
                font-size: 13px;
                background: #82adbf;
                text-transform: uppercase;
                font-weight: 600;
                padding: 10px;
                border-radius: 10px;
                min-width: max-content;
                text-align: center;
            }
        }

        @media (max-width: 1015px) {
            flex-direction: column;
            align-items: center;

            .showTags {
                flex-wrap: wrap;
                margin: 0;

                p {
                    margin: 10px;
                }
            }
        }
    }

    .codePartner {
        margin-top: 20px;
        span {
            font-weight: bold;
        }
    }

    .content {
        h2 {
            color: $color-primary;
            text-transform: uppercase;
            text-align: center;
            font-size: 25px;
            margin: 20px;
            font-weight: bold;
        }

        p {
            color: $color-secondary;
            font-size: 17px;
            text-align: justify;
            margin: 20px 0;
            font-weight: 500;
        }
    }

    .price {
        @include priceStatus();
        margin-top: 20px;
    }

    .contentModal {
        @include initColumnContentModal();

        .informationModal {
            @include columnCenterTopFlex();
            margin: 10px;
            h3 {
                @include title($size: 25px);
                margin-bottom: 10px;
            }

            .desc {
                font-weight: 500;
                margin: 10px 0;

                span {
                    font-weight: bold;
                    font-size: 17px;
                }
            }
        }

        .infoCode {
            @include centerFlex();
            flex-wrap: wrap;
        }

        .button {
            margin-top: 20px;
        }
    }
}
