@import '../../../shared/style/index.scss';

.container {
    display: flex;
    justify-content: center;
    width: 100%;

    .star {
        padding: 5px;
        cursor: pointer;
        transition: color 0.2s;
    }

    .radio {
        display: none;
    }

    .note {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .imgNote {
            display: flex;
            justify-content: center;
            max-width: 90%;
            height: 150px;

            @media (max-width: 380px) {
                align-items: center;
            }

            img {
                height: 220px;
            }
        }

        .starsContainer {
            background: $gradient-opacity;
            color: $color-secondary;
            padding: 40px;
            border-radius: 10px;
            margin-top: 30px;

            @media (max-width: 380px) {
                margin-top: 0px;
            }

            p {
                text-align: center;
                font-size: 18px;
                font-weight: 600;
            }

            .stars {
                display: flex;
                justify-content: center;
                font-size: 26px;
                margin-top: 20px;
            }
        }
    }
}