@import '../../../shared/style/index.scss';

.containerImage {
    @include centerFlex;
    @include borderGradient();
    position: relative;
    border-radius: 20px;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.blue {
    @include borderLinearGradient($gradient-blue-secondary);
}

.large {
    @include minAndMaxSize(110px, 110px);
}
.medium {
    @include minAndMaxSize(105px, 105px);
}
.small {
    @include minAndMaxSize(90px, 90px);
}
.xs {
    @include minAndMaxSize(80px, 80px);
}
