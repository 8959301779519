@import '../../../shared/style/index.scss';

.backdrop {
    position: fixed;
    inset: 0;
    overflow: auto;
    background-color: #000000;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: $zindex-backdrop;

    .modal {
        position: fixed;
        width: 90%;
        min-width: 200px;
        max-width: 502px;
        min-height: 470px;
        max-height: 85%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 30px;
        border: solid 2px $color-secondary;
        transition: all 0.5s;
        background: $gradient-top;
        z-index: $zindex-modal;
        overflow: hidden;

        .modalHeader {
            height: 60px;
            background: $blue-gradient;
            width: 100%;
            border-radius: 25px 25px 0 0;
            position: relative;
            z-index: 20;

            &::before {
                content: '';
                display: block;
                width: 100%;
                position: absolute;
                height: 4px;
                background: white;
                bottom: 0;
            }

            .modalImageContainer {
                @include centerFlex;
                position: relative;
                @include minAndMaxSize(80px, 80px);
                background: $blue-gradient;
                border-radius: 50%;
                bottom: -20px;
                margin-left: auto;
                margin-right: auto;
                border: 4px solid $white;
                padding: 7px;
                overflow: hidden;

                .modalImage {
                    width: 100%;
                    object-fit: contain;
                    max-width: 100%;
                    z-index: 10;
                }
            }
            .closeModal {
                @include centerFlex;
                position: absolute;
                top: 10px;
                right: 20px;
                background: $gradient;
                height: 35px;
                width: 35px;
                border-radius: 50%;
                font-size: 27px;
                font-weight: 700;
                cursor: pointer;
                text-align: center;

                &:hover {
                    transform: scale(1.05);
                }

                span {
                    transform: translateY(-3px);
                }
            }
        }
        .modalContent {
            margin-top: 30px;
            padding: 15px;
            min-height: 340px;
            max-height: 410px;
            overflow: hidden;

            overflow-y: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .tallModal {
        max-height: 600px;
    }
}
