@import '../../../shared/style/index.scss';

.card {
    position: relative;
    width: 300px;
    background: $color-primary;
    border-radius: 20px 0;
    @include centerFlex;
    flex-direction: column;
    box-shadow: $white-shadow;
    padding-top: 80px;

    .logoPartner {
        background-color: white;
        width: 60%;
        padding: 20px;
        border-radius: 20px 0;
        transform: translateY(-50%);
        box-shadow: $white-shadow-blur;
        position: absolute;
        top: 0;

        img {
            width: 100px;
            height: 60px;
            object-fit: contain;
            position: relative;
            left: 50%;
            transform: translate(-50%, 0%) scale(1.3);
        }
    }

    .content {
        width: 100%;
        padding: 50px 40px;
        max-height: 230px;
        border-top: 15px solid $color-secondary;
        
        p {
            text-align: center;
            color: $color-secondary;
            font-weight: 700;
            font-size: 17px;
            margin: auto;
        }
    }
}
