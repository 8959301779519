@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.page {
    background: #e7f9fd;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .active {
        font-weight: bold !important;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            height: 3px;
            width: 110%;
            background: $gradient;
            bottom: -10px;
            left: -5px;
        }
    }

    .experiences {
        margin: 10px;
    }

    .container {
        @include columnCenterTopFlex();
        width: 100%;
        text-align: center;

        .header {
            @include columnCenterTopFlex();
            h1 {
                @include title($size: 45px, $color: $color-quaternary, $transform: initial);
            }

            .textBg {
                @include title($size: 45px, $color: $white, $transform: initial);
                margin-top: $medium;
                background: $gradient;
                padding: 0 $large;
                padding-bottom: $x-small;
                max-width: max-content;
            }

            @include resolution(x-desktop) {
                h1,
                .textBg {
                    font-size: 35px;
                }
            }
            @include resolution(mobile) {
                h1,
                .textBg {
                    font-size: 28px;
                }
            }
        }

        .description {
            width: 80%;
            margin: 55px 0;
            color: white;
            font-weight: bold;
            font-size: $large-font;

            @include resolution(tablet) {
                width: 100%;
            }
        }

        .btn {
            margin: 0;
            margin: 10px;
        }
    }

    .functioning {
        width: 100%;
        @include flex();
        .content {
            width: 50%;
            .header {
                h2 {
                    @include title($size: 28px, $color: $color-secondary, $transform: initial);
                }
                p {
                    @include title($size: 21px !important, $color: $color-secondary, $transform: initial);
                    margin: 20px 0;
                }
            }

            .infoSteps {
                margin: 40px 0;
                h3 {
                    @include title($size: 18px !important, $color: $color-quaternary);
                }

                p {
                    color: $color-secondary;
                    font-weight: 500;
                }

                @include resolution(mobile) {
                    @include columnCenterTopFlex();
                    margin: 10px 0;
                }
            }

            @include resolution(mobile) {
                @include columnCenterTopFlex();

                button {
                    margin-bottom: $large;
                }
            }
        }
        .carousel {
            width: 50%;
            @include centerFlex();
            margin: auto;
            img {
                max-height: 550px;
                object-fit: contain;
                padding-bottom: 40px;
            }
            .indicator {
                width: 9px;
                height: 9px;
                display: inline-block;
                margin: 0 8px;
                background: $white;
                border-radius: 50%;
            }

            .indicatorSelected {
                background: $gradient;
            }
        }
        @media (max-width: 1020px) {
            @include columnCenterTopFlex();

            .content {
                width: 100%;
            }

            .carousel {
                margin-top: 30px;
                width: 100%;

                img {
                    max-height: 500px;
                }
            }
        }

        @media (max-width: 600px) {
            .carousel {
                img {
                    max-height: 350px;
                }
            }
        }
    }

    .matching {
        @include columnCenterTopFlex();
        width: 100%;
        text-align: center;
        &Header {
            @include title($color: $color-secondary, $size: $xx-large-font, $transform: initial);
            margin-bottom: $large;
        }

        &Flow {
            width: 100%;
            display: flex;
            align-items: stretch;
            justify-content: space-evenly;
            margin: 50px 0;

            &Step {
                width: 33%;
                @include columnCenterTopFlex();

                img {
                    padding: $small;
                }

                p {
                    @include title($color: $color-secondary, $size: $large-font, $size-mobile: $medium-font);
                    margin-top: $medium;

                    @include resolution(tablet) {
                        font-size: $medium-font;
                    }
                }
            }

            i {
                font-size: 40px;
                margin: $small;
                padding-top: $medium;
            }

            @include resolution(x-tablet) {
                @include columnCenterTopFlex();
                margin: $medium 0;

                &Step {
                    width: 100%;
                }

                i {
                    transform: rotate(90deg);
                    padding: 0;
                }
            }
        }

        &Description {
            font-weight: bold;
            margin-bottom: $x-large;
        }
    }

    .rewards {
        width: 100%;
        @include columnCenterTopFlex();

        &Header {
            @include columnCenterTopFlex();
            margin: 10px;
            img {
                @include minAndMaxSize(90px, 90px);
            }

            p {
                @include title($size: 28px, $color: $color-quaternary, $transform: initial);
                margin-top: 10px;
                text-align: center;
            }
        }

        &Partner {
            width: 100%;
            @include centerFlex();
            align-items: stretch;
            flex-wrap: wrap;
            gap: 20px;
            text-align: center;
            margin-top: 10px;

            &Content {
                @include columnCenterTopFlex();
                justify-content: space-between;
                height: 100%;
                img {
                    height: 90px;
                    margin-bottom: 10px;
                }

                &Text {
                    color: $color-secondary;
                    font-size: 18px;
                    margin: 10px;
                    padding: 0 20px;
                    span {
                        font-weight: bold;
                    }
                }
            }

            section {
                width: calc(95% / 3);
                min-width: 290px !important;
                @include columnCenterTopFlex();
                padding: 30px 0 60px 0;
                height: auto;
            }

            @media (max-width: 500px) {
                margin-top: 10px;
            }
        }
    }

    .expTools {
        width: 100%;
        background: $bg-page;
        @include columnCenterTopFlex;
        text-align: center;

        &Header {
            img {
                @include minAndMaxSize(120px, 120px);
            }

            h2 {
                @include title($size: 30px, $color: $color-secondary, $transform: initial);
                margin-bottom: 50px;
            }
            padding: 0 50px;

            @media (max-width: 500px) {
                padding: 0;
            }
        }

        .expCardContainer {
            @include centerFlex;
            justify-content: space-around;
            width: 100%;
            margin: 20px 0 30px 0;
            padding: 0 50px;

            section {
                max-width: 600px;
                min-width: 300px;
                margin: 10px 15px;
            }

            @media (max-width: 1000px) {
                flex-wrap: wrap;
            }

            @media (max-width: 500px) {
                padding: 0;
            }
        }

        .carouselItem {
            @include centerFlex();
            display: flex !important;
        }

        .btn {
            margin: 10px;
        }

        @media (max-width: 430px) {
            padding: 60px 0px;
            .expCardContainer {
                section {
                    width: 100%;
                    min-width: 150px;
                    margin: 15px 0;
                }
            }
        }
    }

    .values {
        background: $white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 10px;

        .headerValues {
            display: flex;
            margin: 60px 0 0 60px;

            @media (max-width: 440px) {
                margin: 0;
                justify-content: center;
                padding: 10px;
            }
            .title {
                display: flex;
                align-items: center;

                .miniLogo {
                    width: 70px;
                    height: 70px;
                }

                h2 {
                    @include title($size: 28px, $color: $color-secondary, $transform: initial);
                    margin-left: 20px;

                    span {
                        color: $color-quaternary;
                    }
                }
            }
        }

        .contentValues {
            display: flex;
            justify-content: space-between;

            @media (max-width: 1100px) {
                flex-direction: column-reverse;
                align-items: center;

                .illustration {
                    margin-top: 30px;
                    margin-right: 0px !important;
                    width: 100% !important;

                    img {
                        max-height: 400px !important;
                    }
                }

                .information {
                    margin: 0 !important;
                    width: 90% !important;
                }

                .btnValues {
                    display: flex;
                    justify-content: center;
                }
            }

            .information {
                display: flex;
                flex-direction: column;
                width: 50%;
                margin: 20px 0 0 60px;

                .textContent {
                    font-size: 18px;
                    margin-top: 30px;

                    p {
                        line-height: 30px;
                        font-weight: 600;
                    }
                }

                .cardContainerValues {
                    display: flex;
                    justify-content: space-evenly;
                    margin: 30px 0;
                    flex-wrap: wrap;

                    @media (min-width: 1260px) and (max-width: 1460px) {
                        flex-wrap: nowrap;
                        justify-content: center;
                    }

                    @media (min-width: 1101px) and (max-width: 1259px) {
                        display: grid;
                        grid-template-columns: auto auto;
                        justify-content: center;
                        justify-items: center;
                    }

                    @media (max-width: 820px) {
                        display: grid;
                        grid-template-columns: auto auto;
                        justify-content: center;
                        justify-items: center;
                    }
                }

                .btnValues {
                    button {
                        width: max-content;
                        padding: 6px 20px;
                        margin: 10px;
                        border: none;
                        font-weight: bold;
                        font-size: 16px;
                        transition: transform 0.3s;
                        box-shadow: $soft-shadow;

                        &:hover {
                            transform: scale(1.05);
                        }
                    }
                }
            }

            .illustration {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40%;
                margin-right: 30px;

                @media (max-width: 1500px) {
                    margin-right: 30px;

                    img {
                        max-width: 100% !important;
                    }
                }

                @media (max-width: 460px) {
                    padding: 10px;
                }

                img {
                    max-width: 80%;
                    max-height: 450px;
                }
            }
        }
    }

    footer {
        background: $blue-gradient;

        .footerContent {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin: 0 auto;

            @media (max-width: 580px) {
                padding-top: 30px;
            }
        }
        .footerText {
            color: $white;
            width: calc(33.33% - 40px);
            text-align: center;
            margin: 20px;
            font-weight: bold;

            a {
                color: $white;
                text-decoration: none;
            }

            @media (max-width: 580px) {
                width: calc(100% - 20px);

                &:last-child {
                    border-top: solid 2px #1dbbee;
                    width: 100%;
                    padding-top: 15px;
                }
            }
        }
    }
}
