@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: white;
    border-radius: 20px;
    padding: 15px;
    padding-bottom: 40px;
    margin: 10px;
    position: relative;
    width: 50%;
    min-width: 45%;
    box-shadow: $soft-shadow-content;

    @media (max-width: 800px) {
        width: 100%;
    }

    .header {
        display: flex;

        .logo {
            @include centerFlex;
            @include minAndMaxSize(110px, 110px);
            position: relative;
            background: linear-gradient(to right, white, white) padding-box, $gradient border-box;
            border: 2px solid transparent;
            border-radius: 20px;
            padding: 6px;

            img {
                width: 100%;
                max-height: 80px;
                object-fit: contain;
            }

            @media (max-width: 400px) {
                max-width: 105px;
                min-width: 105px;
                height: 115px;

                img {
                    max-height: 70px;
                }
            }
        }

        .headline {
            @include columnCenterTopFlex();
            align-items: flex-start;
            justify-content: space-between;
            margin: 5px 0px 5px 10px;

            .title {
                text-transform: uppercase;
                font-size: 19px;
                font-weight: bold;
                color: $color-secondary;
                margin: 0;
            }

            .rewards {
                @include flex();
                align-self: flex-start;
                margin-top: 10px;
                background: $gradient-low-opacity;
                padding: 7px 15px;
                border-radius: 10px;
                font-weight: 600;

                img {
                    @include minAndMaxSize(25px, 25px);
                    margin-right: 5px;
                }
            }
        }
        @media (max-width: 450px) {
            flex-direction: column;
            align-items: center;
            .logo {
                margin: 5px 0;
            }

            .headline {
                display: flex;
                align-items: center;
                margin: 5px 0;
                text-align: center;

                .title {
                    margin: 5px 0;
                }

                .rewards {
                    width: auto;
                    align-self: center;
                }
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .tags {
            @include flex();
            flex-wrap: wrap;
            margin-top: 10px;
            li {
                font-weight: 600;
                position: relative;
                font-size: 15px;
                margin: 0 10px;
                cursor: initial;

                &::before {
                    content: '';
                    position: absolute;
                    left: -10px;
                    top: 7px;
                    @include minAndMaxSize(7px, 7px);
                    background: $color-secondary;
                    border-radius: 50%;
                }
            }
        }

        .description {
            overflow: hidden;
            max-width: 100%;
            margin: 5px 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            color: $color-secondary;
            font-weight: 500;
            text-align: start;
            width: 100%;
        }
    }
    .btn {
        margin: 10px 0 5px 0;
        @include columnCenterTopFlex();
        .price {
            @include priceStatus();
        }

        .priceVariant {
            background: $blue-gradient-low-opacity;
            color: $color-quaternary;
        }
    }

    .bg {
        background: $blue-gradient;
        width: calc(100% + 2px);
        height: 30px;
        position: absolute;
        bottom: -1px;
        left: -1px;
        border-radius: 0 0 17px 17px;
    }

    @media (min-width: 1020px) and (max-width: 1300px) {
        .header {
            .headline {
                margin-left: 10px;
                .title {
                    font-size: 16px;
                }
            }
        }
    }
}

// GRADIENT

.gradient {
    background: linear-gradient(to right, white, white) padding-box,
        linear-gradient(to right, #71f2b8, #21bbee) border-box;
    border: 2px solid transparent;

    .content {
        .description {
            color: $color-secondary;
        }
    }

    .bg {
        background: var(--bg-vt);
    }
}

// CARD GRADIENT

.bgCardGradient {
    background: $gradient-low-opacity;
    border: none;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    box-shadow: none;

    .header {
        .logo {
            background: linear-gradient(to right, white, white) padding-box,
                linear-gradient($color-secondary, $color-secondary) border-box !important;
            @include minAndMaxSize(100px, 100px);
        }

        .headline {
            @include columnCenterTopFlex();
            justify-content: space-between;
            margin: 5px 0px 10px 20px;

            .title {
                margin: 0;
            }
            .rewards {
                @include flex();
                background: none;
                padding: 0;

                img {
                    @include minAndMaxSize(25px, 25px);
                    margin-right: 5px;
                }
            }
        }

        @media (max-width: 450px) {
            flex-direction: column;
            align-items: center;
            .logo {
                margin: 5px 0;
            }

            .headline {
                display: flex;
                align-items: center;
                margin: 5px 0;
                text-align: center;

                .title {
                    margin: 5px 0;
                }

                .rewards {
                    width: auto;
                    align-self: center;
                }
            }
        }
    }

    .content {
        .description {
            color: $color-secondary;
        }

        .tags {
            display: none;
        }
    }

    .bg {
        background: none;
    }
}
