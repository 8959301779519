@import '../../../shared/style/index.scss';

.container {
    display: flex;
    @include columnCenterTopFlex;
    overflow: hidden;
    border-radius: 20px;

    .header {
        @include columnCenterTopFlex;
        width: 100%;
        img {
            width: 100%;
            max-height: 300px;
            min-height: 200px;
            object-fit: cover;
            object-position: center;
            background: $bg-blue-hight-opacity;
        }
        .borderGradient {
            width: 100%;
            height: 15px;
            background: $gradient;
        }
    }

    .content {
        padding: 0 40px;
        min-width: 100%;

        @media (max-width: 470px) {
            padding: 0 15px;
        }
        img {
            margin: 25px 0;
            height: 35px;
            object-fit: contain;
        }

        .information {
            .kind {
                color: $color-primary;
                text-transform: uppercase;
                text-align: center;
                font-weight: bold;
                font-size: 20px;
                margin-top: 20px;
            }

            .partner {
                text-align: center;
                margin: 20px 0;

                span {
                    font-weight: bold;
                }
            }

            .tags {
                display: flex;
                justify-content: space-around;
                align-items: center;
                margin: 25px 0;

                .widget {
                    display: flex;
                    align-items: center;
                    margin: 0 5px;

                    .icon {
                        @include centerFlex;
                        background: $color-secondary;
                        width: 50px;
                        height: 50px;
                        min-width: 50px;
                        min-height: 50px;
                        padding: 5px;
                        border-radius: 10px;
                        box-shadow: $soft-shadow;
                        margin-right: 15px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .tagsInformation {
                        p {
                            &:last-child {
                                font-weight: bold;
                            }
                        }
                    }
                }

                .mainTag {
                    display: flex;
                    align-items: center;
                    background: $gradient-opacity;
                    padding: 5px 15px;
                    border-radius: 5px;
                    box-shadow: $soft-shadow;
                    img {
                        width: 30px;
                        height: 30px;
                        min-width: 30px;
                        min-height: 30px;
                        margin-right: 15px;
                    }
                    p {
                        text-transform: uppercase;
                        font-weight: bold;
                    }
                }

                @media (max-width: 900px) {
                    .widget {
                        width: 100%;
                    }

                    .mainTag {
                        width: auto;
                    }
                }

                @media (max-width: 800px) {
                    flex-wrap: wrap;
                    .widget {
                        margin: 10px;
                    }

                    .mainTag {
                        margin: 10px;
                    }
                }
            }
        }

        .description {
            color: $color-secondary;
            font-weight: 500;
            text-align: initial;
        }

        .btn {
            @include centerFlex;
            margin: 25px 0;

            button {
                width: max-content;
                padding: 7px 20px;
                font-weight: bold;
            }

            .thanks {
                padding: 10px 25px;
                border-radius: 5px;
                font-weight: bold;
                font-size: 20px;
                background: $gradient-opacity;
            }
        }
    }
}
