@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.main {
    padding: 10px !important;
    padding-bottom: 0;
    .header {
        display: flex;
        align-items: center;
        border-radius: 17px 17px 0 0;
        width: 100%;
        padding: 5px 10px;

        .logo {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 45px;
            height: 45px;
            padding: 5px;
            background: $color-secondary;
            border-radius: 10px;
            position: relative;

            i {
                font-size: 25px;
                color: transparent;
                background-image: linear-gradient(90deg, #71f2b8, #21bbee);
                -webkit-background-clip: text;
                background-clip: text;
            }
        }

        .title {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 20px;
            padding: 10px;
            color: $color-primary;
        }
    }

    .content {
        .stepContainer {
            display: flex;
            align-items: flex-start;
            margin: 20px 10px;
            height: 80px;

            .stepNumber {
                margin-right: 15px;
                font-size: 20px;
                font-weight: bold;
                span {
                    @include centerFlex;
                    width: 40px;
                    height: 40px;
                    min-width: 40px;
                    min-height: 40px;
                    border: 2px solid transparent;
                    background: $border-gradient;
                    border-radius: 50%;
                }
            }

            p {
                height: max-content;
                span {
                    font-weight: bold;
                }
            }

            &:nth-child(2) {
                .stepNumber {
                    position: relative;
                    span {
                        &::after {
                            content: '';
                            background: $gradient;
                            height: 60px;
                            width: 3px;
                            position: absolute;
                            top: 40px;
                        }
                        &::before {
                            content: '';
                            background: $gradient;
                            height: 60px;
                            width: 3px;
                            position: absolute;
                            bottom: 40px;
                        }
                    }
                }
            }

            &:last-child {
                margin-bottom: 0;
            }

            @media (max-width: 1430px) {
                height: 100px;
                &:nth-child(2) {
                    .stepNumber {
                        span {
                            &::after {
                                height: 80px;
                            }
                            &::before {
                                height: 80px;
                            }
                        }
                    }
                }
            }

            @media (min-width: 600px) and (max-width: 1020px) {
                height: 70px;
                &:nth-child(2) {
                    .stepNumber {
                        span {
                            &::after {
                                height: 50px;
                            }
                            &::before {
                                height: 50px;
                            }
                        }
                    }
                }
            }

            @media (max-width: 370px) {
                height: 120px;
                &:nth-child(2) {
                    .stepNumber {
                        span {
                            &::after {
                                height: 100px;
                            }
                            &::before {
                                height: 100px;
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 1020px) {
            padding-bottom: 30px;
        }
    }
}
