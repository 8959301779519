@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.header {
    .border {
        height: 10px;
        width: 100%;
        background: $blue-gradient;
    }
}

.roadmapContainer {
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;

    .roadmap {
        padding: 80px 0;
        position: relative;

        .rowDashed {
            width: 76%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 0);
            border-top: $color-secondary 5px dashed;
        }

        .stepRoadmap {
            @include centerFlex();
            justify-content: space-between;

            .step {
                background: transparent;
                @include centerFlex();
                flex-direction: column;
                flex-grow: 1;
                height: 190px;
                border-radius: 50px 0;
                margin: 0 40px;
                position: relative;

                img {
                    width: 60px;
                    z-index: 10;
                }

                p {
                    color: $color-secondary;
                    font-weight: bold;
                    font-size: 17px;
                    position: absolute;
                    bottom: 15px;
                }

                &::before {
                    content: '';
                    display: block;
                    background: white;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 3;
                    transform: translate(-50%, -50%);
                    width: 90px;
                    height: 90px;
                    border: dashed 4px $color-quaternary;
                    border-radius: 100%;
                    filter: grayscale(1);
                }

                &::after {
                    content: '';
                    display: block;
                    background: transparent;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 5;
                    transform: translate(-50%, -50%) rotate(-46deg);
                    width: 90px;
                    height: 90px;
                    border: solid 4px $color-primary;
                    border-color: transparent $color-primary $color-primary transparent;
                    border-radius: 100%;
                    filter: grayscale(1);
                }
            }

            .stepActive {
                background: $blue-gradient;
                min-width: 200px;
                max-width: 200px;
                min-height: 200px;
                max-height: 200px;

                &::after,
                &::before {
                    filter: grayscale(0) !important;
                    background: transparent;
                }

                p {
                    color: white;
                }
            }
        }
        @media (max-width: 700px) {
            .rowDashed {
                transform: translate(-50%, -50%);
                width: 0%;
                height: 72%;
                border-right: $color-secondary 5px dashed;
            }

            .stepRoadmap {
                flex-direction: column;

                p {
                    background-color: white;
                    padding: 10px;
                    bottom: 5px !important;
                }
            }

            .stepActive {
                p {
                    background: transparent;
                }
            }
        }
    }
}

.partnerPresentation {
    width: 100%;
    background: $blue-gradient;
    @include columnCenterTopFlex;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    position: relative;
    padding-top: 180px;
    padding-bottom: 50px;
    color: white;

    img {
        width: 330px;
        height: 200px;
        position: absolute;
        top: -50px;
        margin: 0 auto;
    }

    h3 {
        color: $color-quaternary;
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    p {
        max-width: 75%;
    }
}

.partnerStep {
    @include columnCenterTopFlex;
    padding: 40px 10px;
    padding-bottom: 200px;

    img {
        width: 500px;
        height: 250px;
        margin: 0 auto;
    }

    &List {
        @include columnCenterTopFlex;
        width: 100%;
        height: max-content;
        margin-top: 20px;

        .stepContainer {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            background: $blue-gradient;
            color: white;
            font-weight: bold;
            border-radius: 30px 0;
            margin: 15px 0;
            font-size: 18px;
            z-index: 10;
            width: 60%;
            height: 100%;

            &:last-child {
                .stepNumber::after {
                    border: none !important;
                }
            }
            .stepNumber {
                @include centerFlex;
                position: relative;

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 10px;
                    height: 44px;
                    bottom: -44px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    border-right: $color-quaternary 3px dashed;
                    z-index: 5;
                }

                span {
                    @include centerFlex;
                    width: 40px;
                    height: 40px;
                    border-radius: 40px 40px 0 40px;
                    background: $gradient;
                    font-size: 20px;
                    margin: 0px 12px;
                }

                .borderBg {
                    height: 100%;
                    width: 10px;
                    background: $gradient-top;
                }
            }

            .textPartner {
                margin: 5px;
                margin-left: 15px;
                h3 {
                    color: $color-quaternary;
                    font-weight: bold;
                    margin: 10px 0;
                    font-size: 18px;
                }

                p {
                    margin-bottom: 15px;
                }
            }

            @media (max-width: 1000px) {
                width: 90%;
            }

            @media (max-width: 600px) {
                flex-direction: column;
                text-align: center;

                .stepNumber {
                    flex-direction: column;
                    span {
                        border-radius: 40px;
                        margin: 12px 0;
                    }
                    .borderBg {
                        background: $gradient;
                        width: 100%;
                        height: 10px;
                    }
                    &::after {
                        border: none;
                    }
                }
            }
        }
    }
}

.lastInfo {
    width: 100%;
    background: $gradient-opacity;
    display: flex;
    justify-content: center;

    .cardInfo {
        width: 45%;
        max-width: 510px;
        background: $blue-gradien-top;
        border-radius: 50px 0;
        @include columnCenterTopFlex;
        color: white;
        font-size: 20px;
        transform: translateY(-100px);

        header {
            width: 100%;
            text-align: center;
            h2 {
                font-size: 40px;
                padding: 10px 0;
                font-weight: bold;
                color: $color-quaternary;
            }
            .borderBg {
                height: 10px;
                width: 100%;
                background: $color-quaternary;
            }
        }

        .textCenter {
            text-align: center;
            width: 70%;
            padding-top: 20px;
        }

        .iconBo {
            @include centerFlex();
            height: 140px;
            width: 100%;
            margin: 20px 0px;

            img {
                height: 140px;
                width: 140px;
            }
        }

        .codeContainer {
            @include columnCenterTopFlex;

            .code {
                color: $color-quaternary;
                font-weight: bold;
                font-size: 30px;
                margin: 10px 0 20px 0;
            }
        }

        .btn {
            margin: 10px 0 30px 0;

            button {
                padding: 7px 25px;
                font-size: 25px;
                border: 2px solid $color-quaternary;

                &:hover {
                    border: 2px solid transparent;
                }
            }
        }

        @media (max-width: 1000px) {
            width: 75%;
        }

        @media (max-width: 600px) {
            width: 85%;
            font-size: 18px;

            .btn {
                button {
                    padding: 5px 15px;
                    font-size: 18px;
                }
            }
        }
    }
}

.exit {
    margin: 20px;
    a {
        text-decoration: none;
        color: $color-secondary;
        &:hover {
            font-size: 16.5px;
        }
    }
}
