@import '../../../shared/style/mixins';
@import '../../../shared/style/variable';

.container {
    h2 {
        @include title($color: $color-secondary, $weight: 600, $transform: initial, $size-mobile: 17px);
        padding: 15px 20px;

        span {
            font-weight: bold;
            color: $color-primary;
            text-transform: uppercase;
        }
    }

    .mosaic {
        display: grid;
        grid-template-columns: 50% 50%;
        justify-items: center;
        align-items: stretch;
        width: 100%;

        section,
        > div {
            width: 90% !important;
            @media (max-width: 500px) {
                width: 100% !important;
            }
        }
        @media (max-width: 800px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}
