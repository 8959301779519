@import '../../../shared/style/index.scss';

.container {
    @include centerFlex;
    width: 100% !important;
    margin: 10px 0;
    font-size: 20px;

    .img {
        margin-right: 5px;
        width: auto;
        i {
            color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            background-image: $gradient;
        }
    }

    p {
        color: $color-secondary;
    }
}
