@import './../../shared/style/variable';
@import './../../shared/style/mixins';

.navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    z-index: 500;

    // @media (min-width: 1020px) {
    //     position: absolute;
    //     top: 0;
    //     z-index: 500;
    //     justify-content: end;
    //     height: 90px;

    //     .container {
    //         justify-content: flex-end !important;
    //         margin-right: 25px;

    //         .content {
    //             width: max-content !important;
    //         }
    //     }
    // }

    @include resolution(x-desktop) {
        .container {
            .content {
                .list {
                    flex-direction: column;
                    align-items: center;
                    margin: 0 5px;

                    p {
                        margin-right: 5px !important;
                    }
                    i {
                        display: none;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 600px) {
        height: 60px;
    }

    @media screen and (max-width: 370px) {
        height: 55px;
    }

    .container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: auto;
        height: 100%;

        .content {
            @include flex();
            justify-content: space-evenly;
            gap: $small;
            height: 100%;
            position: relative;

            .list {
                position: relative;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: $x-small $small;
                .icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    width: 50px;
                    height: 50px;
                    border-radius: 10px;
                    padding: 5px;

                    img {
                        width: 30px;
                        height: 30px;
                        z-index: 10;
                    }
                }

                p {
                    color: white;
                    white-space: nowrap;
                }

                .menuToggler {
                    background: none;
                    width: 35px;
                    border: none;
                    cursor: pointer;
                    position: relative;
                    outline: none;
                    z-index: 999;

                    span {
                        display: block;
                        height: 3px;
                        background-color: $color-primary;
                        margin: 5px 0;
                        position: relative;
                        transition: 0.3s linear;
                        border-radius: 5px;
                    }
                }

                @media screen and (max-width: 1019px) {
                    .icon {
                        width: 40px;
                        height: 40px;

                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }

                    p {
                        font-size: 14px;
                    }
                }

                @media screen and (max-width: 600px) {
                    margin: 0;
                    .icon {
                        width: 35px;
                        height: 35px;

                        img {
                            width: 28px;
                            height: 28px;
                        }
                    }

                    p {
                        font-size: 12px;
                        margin: 0;
                    }
                }

                @media screen and (max-width: 370px) {
                    .icon {
                        width: 30px;
                        height: 30px;

                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }
                    p {
                        font-size: 11px;
                    }
                }
            }
            @include resolution(mobile) {
                gap: $x-small;
            }

            .active {
                .icon {
                    z-index: 10;
                    background: $border-gradient-bg-blue;
                    border: 1px solid transparent;
                }
            }
        }
    }
}

.none {
    display: none;
}

.backdropFullScreenMenu {
    position: fixed;
    inset: 0;
    overflow: auto;
    z-index: $zindex-backdrop;
    .fullScreenMenu {
        animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
        background: $color-quaternary;
        display: flex;
        position: fixed;
        top: 0;
        right: 0;
        width: 80vw;
        height: 100vh;
        max-width: 300px;
        overflow: hidden;
        z-index: $zindex-modal;
        justify-content: center;
        padding: 15px;
        box-shadow: $soft-shadow-content;

        span {
            position: fixed;
            top: 20px;
            right: 20px;
            color: $color-secondary;
            font-size: 25px;
            font-weight: bold;
            cursor: pointer;

            &:hover {
                transform: scale(1.03);
            }
        }

        ul {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            li {
                font-size: x-large;
                padding: 10px;

                &:hover {
                    transform: scale(1.03);
                    font-weight: 500;
                }
            }
        }
    }
    .closeFullScreenMenu {
        animation: slide-away 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
    }
}

@keyframes slide-away {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(1000px);
        opacity: 0;
    }
}

@keyframes slide-in-right {
    0% {
        transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

li {
    cursor: pointer;
}
