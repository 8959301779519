@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.arrowContainer {
    background: $blue-gradient;
    border-radius: 50%;
    @include minAndMaxSize(20px, 20px);
    @include centerFlex();
    margin: 0 5px 0 10px;

    i {
        color: $color-quaternary;
        font-size: 18px;
    }
}
