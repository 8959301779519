@import '../../../../shared/style/variable';
@import '../../../../shared/style/mixins';

.container {
    padding: $medium $small;
    width: 100%;

    .explication {
        @include flex();
        justify-content: space-between;

        > p {
            font-weight: 500;
            font-size: $medium-font;
            margin-right: $small;

            @include resolution(tablet) {
                font-size: $normal-font;
            }
        }

        @include resolution(tablet) {
            @include columnCenterTopFlex();
            text-align: center;
            button {
                margin-top: $small;
            }
        }

        button {
            min-width: max-content;
        }
    }

    .iframeContainer {
        @include borderGradient($white, 2px, $color-quaternary-low-opacity, $color-primary-low-opacity);
        overflow: hidden;
        padding: 0;
        margin: $medium 0;

        .header {
            h3 {
                text-transform: initial;
                font-size: $medium-font;
            }
        }

        iframe {
            margin: $small;
            min-height: 500px;
            @include borderGradient($white, 2px, $color-quaternary-low-opacity, $color-primary-low-opacity);
            border-radius: $medium;
            width: auto;
        }
    }

    .shareProfile {
        @include columnCenterTopFlex();
        background: $gradient-low-opacity;
        margin-bottom: $medium;
        font-size: $medium-font;
        > p {
            font-weight: bold;
            text-align: center
        }
        .formation {
            @include columnCenterTopFlex();
            text-align: center;
            margin: $small 0;

            p {
                @include title($color: $color-secondary);
                margin: $small 0;
            }

            span {
                margin: $small 0;
            }
        }

        .checkbox {
            margin-bottom: $medium;
        }

        .separator {
            width: 100%;
            height: $x-small;
            background: $blue-gradient;
            border-radius: $small;
        }

        button {
            margin-top: $large;
        }

        .consentInfo {
            font-size: $small-font;
            width: 50%;
            text-align: center;
            margin: $small auto;
        }
    }

    .matchsCard {
        background: $gradient-low-opacity;

        &Container {
            margin-bottom: $medium;
            .headerMatchs {
                @include flex();
                margin-bottom: $small;

                img {
                    @include minAndMaxSize(30px, 30px);
                    margin-right: $small;
                }

                h4 {
                    @include title($color: $color-secondary);
                }

                @include resolution(mobile) {
                    @include columnCenterTopFlex();

                    img {
                        margin: 0;
                        margin-bottom: $small;
                    }
                }
            }
            p {
                font-weight: 500;
            }

            @include resolution(mobile) {
                text-align: center;
            }
        }
    }

    .replayMatchs {
        @include borderGradient($white, 2px, $color-quaternary-low-opacity, $color-primary-low-opacity);

        button {
            max-width: max-content;
            text-transform: initial;
            margin: $large 0 $x-small 0;

            @include resolution(mobile) {
                margin: $large auto $x-small auto;
            }
        }
    }

    .gauge {
        max-height: 70px;
    }
}
